import React, { ReactNode } from "react";
import { OnBoardingFlowViewProps } from "@identity/views-on-boarding/flow";
import { withAppContext } from "@identity/context";
import { TaskApplicationFlow, PaginatedResult, StepFlow, ValidationType, Notifications } from "interfaces";
import OnBoardingServices from "services/onboarding";
import { ApiResponseError } from "@curus/api";
import TaskJointList from "components/on-boarding/flow/list/tasks-joints"
import TaskFlowInfo from "components/on-boarding/flow/info/portal";
import StepOtpInfo from "components/on-boarding/flow/validation/otp";
import StepIdentityInfo from "components/on-boarding/flow/validation/identity";
import StepAgreementTaskInfo from "components/on-boarding/flow/step/agreement/portal";
import { RouteTaskNotificationParams } from "routing/param";


export interface ClarienTaskJointFlowViewProps extends OnBoardingFlowViewProps { }
export interface ClarienTaskJointFlowViewState {
    taskApplicationFlows: TaskApplicationFlow[] | undefined;
    notifications: Notifications[];
    tableOptions: any;
    routeNotifications: RouteTaskNotificationParams | undefined;
    notificationType: string | undefined
    selected: TaskApplicationFlow;
    taskFlow: TaskApplicationFlow;

}

export class ClarienTaskJointFlowView<P extends ClarienTaskJointFlowViewProps, S extends ClarienTaskJointFlowViewState> extends React.Component<P, S> {
    protected obServices: OnBoardingServices = new OnBoardingServices();

    componentDidMount = (): void => {
        this.fetchTaskJointFlows();
        this.props.showLoading(true);

    }

    constructor(props: P) {
        super(props);
        this.state = {
            taskApplicationFlows: undefined,
            tableOptions: {
                noDataText: this.props.resources.translate('table-empty'),
            },
            routeNotifications: undefined,
            notificationType: undefined

        } as S;
        this.props.configureClass(this.obServices);
    }

    fetchTaskJointFlows = (): void => {
        this.obServices
            .jointTaskFlows({
                filter: {
                    principalId: this.props.match.params.instanceFlowId
                }
            })
            .then((paginatedFlow: PaginatedResult<TaskApplicationFlow>) => {
                this.setState({ taskApplicationFlows: paginatedFlow.content });
                console.log('con parser: ');
                console.log(this.state.taskApplicationFlows);

                this.props.showLoading(false);

            })
            .catch((response: ApiResponseError) => {
                this.props.showAlert(response.message);
                this.props.showLoading(false);
            });
    }

    fetchTaskFlow = (instanceFlowId: string): void => {
        this.props.showLoading(true);
        this.obServices
            .taskFlow({ instanceFlowId })
            .then((taskFlow: TaskApplicationFlow) => {
                this.setState({ taskFlow });
                console.log('con parser: ');
                console.log(taskFlow);
                this.fetchTaskIDVNotifications();
                this.props.showLoading(false);

            })
            .catch((response: ApiResponseError) => {
                this.props.showAlert(response.message);
                this.props.showLoading(false);
            });
    }

    fetchTaskIDVNotifications = (): void => {
        this.props.showLoading(true);
        this.obServices
            .taskNotifications({taskId:this.state.taskFlow?.id,notificationType: "IDV"})
             .then((notifications: Notifications[])=>{
                 this.props.showLoading(false);
                 this.setState({notifications});
             })
             .catch(()=> {
                 this.props.showLoading(false);
             })
    }

    fetchTaskNotifications = (notificationType: string): Promise<Notifications[]> => {
        this.props.showLoading(true);
        console.log(this.state.notificationType);
        return this.obServices
            .taskNotifications({taskId:this.state.taskFlow?.id,notificationType: notificationType})
             .then((notifications: Notifications[])=>{
                 this.props.showLoading(false);
                 return notifications;
             })
             .catch(()=> {
                 this.props.showLoading(false);
                 return [];
             })
        } 

    handleSelected?= (selected: TaskApplicationFlow): void => {
        console.log(selected)
        this.fetchTaskFlow(selected.id);
        this.setState({ selected });
    }

    handleShowExtraInfo = async (type: ValidationType, detail: any): Promise<JSX.Element | undefined> => {
        const step: StepFlow = this.state.taskFlow!.steps.find((s: StepFlow) => s.type === type)!;
        this.fetchTaskJointFlows();
        switch (type) {
            case ValidationType.OTP: {
                return <div>
                    {this.obServices.obParser.formatStepStatusAsAlert(step.status)}
                    <div className="card">
                        <div className="card-body"><StepOtpInfo step={step}
                            tableOptions={this.state.tableOptions} /></div>
                    </div>
                </div>
            }
            case ValidationType.IDENTITY: {

                return <div>
                    {this.obServices.obParser.formatStepStatusAsAlert(step.status)}
                    <div className="card">
                        <div className="card-body"><StepIdentityInfo step={step}

                            tableOptions={this.state.tableOptions} /></div>
                    </div>
                </div>
            }
            case ValidationType.AGREEMENT: {
                let notificationType= "OSS";
                this.setState({notificationType});
                let notifications: Notifications[] = await this.fetchTaskNotifications(notificationType);


                return <div>
                    {this.obServices.obParser.formatStepStatusAsAlert(step.status)}
                    <div className="card">
                        <div className="card-body"><StepAgreementTaskInfo step={step}
                           
                            tableOptions={this.state.tableOptions}
                            notifications={notifications} /></div>
                    </div>
                </div>
            }
        }
    }



    render = (): ReactNode => {
        return (
            <div className="card-body">
                <div className="panel fresh-color panel-info">
                    <div className="panel-heading">
                        <div className="title">{this.props.resources.translate('select-joint-account')}</div>
                    </div>
                    <TaskJointList taskApplicationFlows={this.state.taskApplicationFlows}
                        handleSelected={this.handleSelected} />
                </div>


                {this.state.taskFlow && <TaskFlowInfo flow={this.state.taskFlow}
                    handleShowExtraInfo={this.handleShowExtraInfo}
                    tableOptions={this.state.tableOptions}
                    notifications={this.state.notifications} />}

            </div>
        )

    }
}

export default withAppContext(ClarienTaskJointFlowView);
