import { Validator, Formatter } from "@curus/utils";
import { IdentityClass, IdentityConfig, IdentityResources } from "@identity/classes";
import { PERSON_TYPE_USER } from "@identity/constants-person";
import { PERSON_ID_PARAM, PERSON_TYPE_PARAM } from "@identity/routing-person/param";

const SERVICE_ID_LENGTH = 11;

class ClarienResources extends IdentityClass {
    protected formatter: Formatter<IdentityConfig, IdentityResources> = new Formatter();
    protected validator: Validator<IdentityConfig, IdentityResources> = new Validator();
    
    checkPersonId = (personId: string): boolean => {
        if (personId === undefined || personId === null) return false;
        if (personId.startsWith('curus_')) return true;
        return this.validator.checkRUT(personId);
    }

    cleanRUT = (personId: string): string => {
        while (personId[0] === '0') personId = personId.slice(1);
        personId = personId.trim().replace(/[^0-9kK]+/g, "");
        return personId.toUpperCase();
    }

    formatPersonId = this.formatter.formatRUT;

    parseRouteParam = (param: string, value: string): string => {
        switch (param) {
            case PERSON_ID_PARAM: return value.endsWith('k') ? value.toUpperCase() : value;
            default: return value;
        }
    }

    parseServiceParam = (param: string, value: string): string => {
        switch (param) {
            case PERSON_ID_PARAM: return value.endsWith('k') ? value.toUpperCase() : value;
            case PERSON_TYPE_PARAM: return value === PERSON_TYPE_USER ? 'admin/users' : 'users';
            default: return value;
        }
    }

    route = (route: string, params: any): string => {
        Object.entries(params).forEach(([k, v]) => {
            const param: string = ':' + k;
            const value: string = this.parseRouteParam(param, v as string);
            if (value !== undefined) route = route.replace(param, value);
        });
        return route;
    }

    searchablePersonId = (personId: string) => {
        if (personId === undefined || personId === null) return '';
        if (personId.startsWith('curus_')) return personId;
        return this.cleanRUT(personId);
    }

    servicePersonId = (personId: string) => {
        if (personId === undefined || personId === null) return '';
        if (personId.startsWith('curus_')) return personId;
        const cleaned = this.searchablePersonId(personId);
        return '0'.repeat(SERVICE_ID_LENGTH - cleaned.length) + cleaned;
    }
}

const resources: ClarienResources = new ClarienResources();
export default resources;
