import React, { ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import { AppContextProps, withAppContext } from "@identity/context";
import { OnboardingApplicationFlow, StepFlow, ValidationType, Notifications} from "interfaces";
import OnBoardingParser from "parser/onboarding";
import OnBoardingFlowStep from "components/on-boarding/flow/step";
import NotificationsIdentityList from "components/on-boarding/flow/validation/identity/notifications";


export interface OnBoardingFlowInfoProps extends RouteComponentProps, AppContextProps {
    flow: OnboardingApplicationFlow;
    handleShowExtraInfo: (type: ValidationType, detail: any) => Promise<JSX.Element | undefined>;
    notifications: Notifications[];
    tableOptions: any
}
export interface OnBoardingFlowInfoState {
    stepDetail: JSX.Element | undefined;
    activeStep: ValidationType | undefined;
    prevFlow: OnboardingApplicationFlow | undefined ;
    
    

}

class OnBoardingFlowInfo extends React.Component<OnBoardingFlowInfoProps, OnBoardingFlowInfoState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: OnBoardingFlowInfoProps) {
        super(props);
        this.state = {
            activeStep: undefined,
            stepDetail: undefined,
            prevFlow: undefined,
        };

        this.props.configureClass(this.obParser);
    }
    
    static getDerivedStateFromProps = (props: OnBoardingFlowInfoProps, state: OnBoardingFlowInfoState) => {
        if (props.flow !== undefined && props.flow !== state.prevFlow) {
          return { prevFlow: props.flow, stepDetail: undefined, activeStep: undefined };
        }
        return null;
      }

    goBack = (): void => {
        
        this.props.history.goBack();
    }

    handleShowExtraInfo = (type: ValidationType, detail: any): void => {
        this.props.handleShowExtraInfo(type, detail)
        .then((stepDetail: JSX.Element | undefined )=>
        {
            this.setState({ activeStep: type, stepDetail });
        })
        
    }

    render = (): ReactNode => {
        return (
            <div>
                {this.obParser.formatFlowStatusAsAlert(this.props.flow.onboardingStatus)}
                <div className="row">
                    <div className='col-md-6'>
                        <div className={`card`}>
                            <div className="card-header">
                                <div className="card-title">
                                    <div className="title">{this.props.resources.translate('curus-on-boarding-flow-info')}</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-custom">
                                    <tbody className="data">
                                        <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('id-list')}:</th>
                                            <td colSpan={3}>{this.props.flow.id}</td>
                                        </tr>
                                        <tr>
                                            <th className="col-lg-3"> {this.props.resources.translate('phone-mobile')}:</th>
                                            <td colSpan={3}>{this.props.flow.cellphone}</td>
                                        </tr>
                                        <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('date-creation')}:</th>
                                            <td colSpan={3}>{this.props.flow.createDate.toString()}</td>
                                        </tr>
                                        <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('clarien-product')}:</th>
                                            <td colSpan={3}>{this.props.resources.translateEnum(this.props.flow.clarienProduct)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button className="btn btn-info" onClick={this.goBack}>{this.props.resources.translate('back')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div>
                    <NotificationsIdentityList notifications = {this.props.notifications}
                    tableOptions={this.props.tableOptions}
                    />
                </div>
                
                <div className="margin-card-top">
                    <div className="row">
                        {this.props.flow.steps.map((s: StepFlow, i: number) => {
                            const colSize: number = Math.max(12 / this.props.flow.steps.length, 3);
                            return <OnBoardingFlowStep number={i + 1}
                                step={s}
                                colSize={colSize}
                                activeStep={this.state.activeStep}
                                handleShowExtraInfo={this.handleShowExtraInfo} />

                        })}
                    </div>
                </div>
                {this.state.stepDetail && this.state.stepDetail}

            </div>
        )
    }
}

export default withAppContext(OnBoardingFlowInfo);
