import { CardMenuItem } from "@curus/cards-menu";
import PermissionRoute from "@curus/permission-route";
import { CURUS_ON_BOARDING_FLOW_ROUTE, CURUS_ON_BOARDING_ROUTE } from "@identity/routing-on-boarding";
import { ON_BOARDING_ADMIN_INSTANCE_GET_PERMISSION, ON_BOARDING_ADMIN_PERMISSION, ON_BOARDING_PERMISSION } from "@identity/routing-on-boarding/permission";
import OnBoardingDashboard from "views/on-boarding/dashboard"
import OnBoardingFlowInfo from "views/on-boarding/flow"
import ClarienOnBoardingJointFlow from "views/on-boarding/joint-flow"
import OnboardingPortalDashboard from "views/portal/dashboard"
import React from "react";
import { CURUS_ON_BOARDING_JOINT_FLOW_ROUTE, CURUS_ON_BOARDING_PORTAL_FLOW_ROUTE, CURUS_ON_BOARDING_PORTAL_JOINT_FLOW_ROUTE, CURUS_ON_BOARDING_PORTAL_ROUTE } from "routing";
import TaskFlowInfo from "views/portal/flow";
import ClarienTaskJointFlow from "views/portal/joint-flow";

// Tarjeta menú (entrada)
export const CURUS_ON_BOARDING_NAVIGATION_ENTRY_POINT: CardMenuItem = { 
    id: 'OfficeListMainMenu', 
    icon: 'fa-address-card', 
    title: 'curus-on-boarding', 
    text: 'curus-on-boarding-manage', 
    color: 'yellow', 
    path: CURUS_ON_BOARDING_ROUTE, 
    permissions: [ON_BOARDING_PERMISSION] 
};

export const CURUS_ON_BOARDING_PORTAL_NAVIGATION_ENTRY_POINT: CardMenuItem = { 
    id: 'OfficeListMainMenu', 
    icon: 'fa-address-card', 
    title: 'admin-portal', 
    text: 'admin-portal-manage', 
    color: 'green', 
    path: CURUS_ON_BOARDING_PORTAL_ROUTE, 
    permissions: [ON_BOARDING_PERMISSION] 
};

// Rutas React
export default [
    <PermissionRoute key="onBoardingFlowInfo" permissions={[ON_BOARDING_ADMIN_INSTANCE_GET_PERMISSION]} path={CURUS_ON_BOARDING_FLOW_ROUTE} exact component={OnBoardingFlowInfo} />,
    <PermissionRoute key="onBoardingJointFlows" permissions={[ON_BOARDING_ADMIN_INSTANCE_GET_PERMISSION]} path={CURUS_ON_BOARDING_JOINT_FLOW_ROUTE} exact component={ClarienOnBoardingJointFlow} />,
    <PermissionRoute key="onBoardingDashboard" permissions={[ON_BOARDING_ADMIN_PERMISSION]} path={CURUS_ON_BOARDING_ROUTE} exact component={OnBoardingDashboard} />,
    <PermissionRoute key="onBoardingPortalDashboard" permissions={[ON_BOARDING_ADMIN_PERMISSION]} path={CURUS_ON_BOARDING_PORTAL_ROUTE} exact component={OnboardingPortalDashboard} />,
    <PermissionRoute key="TaskFlowInfo" permissions={[ON_BOARDING_ADMIN_INSTANCE_GET_PERMISSION]} path={CURUS_ON_BOARDING_PORTAL_FLOW_ROUTE} exact component={TaskFlowInfo} />,
    <PermissionRoute key="taskJointFlows" permissions={[ON_BOARDING_ADMIN_INSTANCE_GET_PERMISSION]} path={CURUS_ON_BOARDING_PORTAL_JOINT_FLOW_ROUTE} exact component={ClarienTaskJointFlow} />,
];