import { ApiResponseError } from "@curus/api";
import { AppContextProps, withAppContext } from "@identity/context";
import { AgrementPdf, DocumentAgreement } from "interfaces";
import React, { ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";
import { RouteAgreementDocumentParams } from "routing/param";
import OnBoardingServices from "services/onboarding";
import { Document, Page, pdfjs } from "react-pdf";

export interface AgreementPDFProps extends RouteComponentProps, AppContextProps {
    document: DocumentAgreement;
    packageId: string | undefined;
    tableOptions: any;
}
export interface AgreementPDFState {
    pdf: any | undefined;
    params: RouteAgreementDocumentParams;
    tableOptions: any;
    renderPdf: any;
    base64: string | undefined;
}

class AgreementPDF extends React.Component<AgreementPDFProps, AgreementPDFState> {
    protected obServices: OnBoardingServices = new OnBoardingServices();
    _isMounted = false;

    componentDidMount = (): void => {
        this.fetchDocument();
        this._isMounted = true;
    }

    constructor(props: AgreementPDFProps) {
        super(props);
        this.state = {
            params: {
                packageId: this.props.packageId,
                documentId: this.props.document.id
            },
            pdf: undefined,
            renderPdf: undefined,

            base64: undefined,
            tableOptions: {
                noDataText: this.props.resources.translate('table-empty'),
            }
        };
        this.props.configureClass(this.obServices);
    }

    fetchDocument = (): void => {
        this.props.showLoading(true);
        this.obServices
            .document(this.state.params)
            .then((pdf: any) => {
                let base64 = "data:application/pdf;base64," + pdf;
                this.setState({ base64 });
                this.props.showLoading(false);
            })
            .catch((response: ApiResponseError) => {
                this.props.showAlert(response.message);
                this.props.showLoading(false);
            });
    }



    render = (): ReactNode => {
        return (
            <div>
                PDF
                <div>
                    <iframe style={{width:"100%",height:"80vh"}} src={this.state.base64}></iframe>

                </div>
            </div>
        )
    }
}

export default withAppContext(AgreementPDF);