import React from "react";
import { Formatter, Parser } from "@curus/utils";
import { IdentityClass, IdentityConfig, IdentityResources } from "@identity/classes";
import { OnboardingAgreementPackageAPI, OnboardingApplicationFlowAPI, OnboardingApplicationFlow, MachineSecret, MachineSecretAPI, PaginatedResultAPI, PaginatedResult, OnboardingStatus, StepFlowAPI, StepFlow, ValidationType, OtpValidationAPI, OtpValidation, VerificationOtp, IdentityValidationAPI, IdentityValidation, StepStatus, ListValidationOtp, ListValidationIdentity, Status, OnboardingAgreementPackage, PackageRoleAPI, PackageRole, SignerAPI, Signer, SignatureAPI, Signature, AuthAPI, Auth, AttachReqStatus, DocumentAgreementAPI, DocumentAgreement, ApprovalAPI, Approval, AgrementPdf, AttachmentRequirement, AttachmentBase64API, AttachmentBase64, NotificationsAPI, Notifications, TaskApplicationFlowAPI, TaskApplicationFlow, TaskStatus, TaskAgreementPackageAPI, TaskAgreementPackage } from "interfaces";
import { utcToZonedTime } from "date-fns-tz";

export default class OnBoardingParser extends IdentityClass {
    protected formatter: Formatter<IdentityConfig, IdentityResources> = new Formatter();
    protected parser: Parser<IdentityConfig, IdentityResources> = new Parser();


    parseOnboardingApplicationFlow = (flow: OnboardingApplicationFlowAPI): OnboardingApplicationFlow => {
        return {
            id: flow.id,
            clarienProduct: flow.clarienProduct,
            cellphone: flow.cellphone,
            createDate: new Date(flow.startDatetime),
            onboardingStatus: flow.onboardingStatus,
            steps: !!flow.stepFlows && flow.stepFlows.map(this.parseStepFlow),
            ipClient: flow.ipClient,
            type: flow.type,
            jointAccountType: flow.jointAccountType
            
        }
    };

    parseTaskApplicationFlow = (taskFlow: TaskApplicationFlowAPI): TaskApplicationFlow => {
        return {
            id: taskFlow.id,
            cellphone: taskFlow.cellphone,
            createDate: new Date(taskFlow.startDatetime),
            steps: !!taskFlow.stepFlows && taskFlow.stepFlows.map(this.parseStepFlow),
            taskStatus: taskFlow.taskStatus,
            actionType: taskFlow.actionType,
            jointAccountType: taskFlow.jointAccountType,
            type: taskFlow.type  
        }
    };

    parseStepFlow = (step: StepFlowAPI): StepFlow => {
        let details: any = step.detailValidations;
        let type: ValidationType = step.type;
        switch (step.type) {
            case ValidationType.OTP: {
                details = step.detailValidations.map(this.parseOTPValidation, step.detailValidations.indexOf);
                break;
            }
            case ValidationType.IDENTITY: {
                details = step.detailValidations.map(this.parseIdentityValidation, step.detailValidations.indexOf);
                break;
            }
            case ValidationType.AGREEMENT: {
                details = step.detailValidations.map(this.parseAgreementPackage);
                break;
            }
        }

        return {
            status: step.status,
            createDate: new Date(step.creationDate),
            details,
            type,
            provider: step.provider
        }
    };

    parseIdentityValidation = (validationAPI: IdentityValidationAPI, index: number): IdentityValidation => ({
        index: index + 1,
        dates: validationAPI.dates,
        matches: validationAPI.matches,
        status: validationAPI.status,
        biometryScores: validationAPI.biometryScores,
        images: validationAPI.images,
        documentScores: validationAPI.documentScores,
        documentfields: validationAPI.documentfields
    });

    parseOTPValidation = (validationAPI: OtpValidationAPI, index: number): OtpValidation => ({
        index: index + 1,
        dates: validationAPI.dates,
        matches: validationAPI.matches,
        status: validationAPI.status,
        verifications: validationAPI.verifications,
        contact: validationAPI.contact,
        deliveryType: validationAPI.deliveryType

    });


    parseVerificationsOTP = (verificationOtp: VerificationOtp): VerificationOtp => ({
        date: verificationOtp.date,
        status: verificationOtp.status,
        remainingAttempts: verificationOtp.remainingAttempts
    });

    parseMachineSecret = (secret: MachineSecretAPI): MachineSecret => {
        //if (secret === undefined || secret === null) return undefined;
        return { ...secret.applicationData };
    };

    parseOtpValidationList = (otpValidations: OtpValidation[]): ListValidationOtp[] => {
        let list: ListValidationOtp[] = [];

        otpValidations.map((otpValidation) => {
            let elem: ListValidationOtp = {
                index: otpValidation.index,
                result: otpValidation.status.result,
                comment: otpValidation.status.comment,
                creationDate: otpValidation.dates["created"],
                lastEventDate: otpValidation.dates["last_event"],
                dates: otpValidation.dates,
                matches: otpValidation.matches,
                verifications: otpValidation.verifications,
                contact: otpValidation.contact,
                deliveryType: otpValidation.deliveryType,
                status: otpValidation.status
            };
            list.push(elem);
        });
        return list;
    };

    parseIdentityValidationList = (identityValidations: IdentityValidation[]): ListValidationIdentity[] => {
        let list: ListValidationIdentity[] = [];

        identityValidations.map((identityValidation) => {
            let elem: ListValidationIdentity = {
                index: identityValidation.index,
                result: identityValidation.status.result,
                comment: identityValidation.status.comment,
                creationDate: identityValidation.dates["created"],
                lastEventDate: identityValidation.dates["last_event"],
                dates: identityValidation.dates,
                matches: identityValidation.matches,
                biometryScores: identityValidation.biometryScores,
                images: identityValidation.images,
                documentScores: identityValidation.documentScores,
                documentfields: identityValidation.documentfields,
                status: identityValidation.status
            };
            list.push(elem);
        });
        return list;
    };


    parseNotificationsList = (notifications: NotificationsAPI[]): Notifications[] => {
        let list: Notifications[] = [];

        notifications.map((notification) => {
            let elem: Notifications = {
                id: notification.id,
                onboardingApplicationId: notification.onboardingApplicationId,
                timestamp: JSON.parse(notification.jsonContent).timestamp,
                trigger: JSON.parse(notification.jsonContent).trigger,
                message: JSON.parse(notification.jsonContent).message,
                name: JSON.parse(notification.jsonContent).name,
                sessionUser: JSON.parse(notification.jsonContent).sessionUser,
                createdDate: JSON.parse(notification.jsonContent).createdDate
            };
            list.push(elem);
        });
        return list;
    };



    parsePaginatedResult = <A extends any, T extends any>(paginated: PaginatedResultAPI<A>, parser: (el: A) => T): PaginatedResult<T> => ({
        content: paginated.content.map(parser),
        empty: paginated.empty,
        first: paginated.first,
        last: paginated.last,
        numberOfElements: paginated.numberOfElements,
        size: paginated.size,
        totalElements: paginated.totalElements,
        totalPages: paginated.totalPages,
    })

    parseAgreementDocumentFile = (agreementdocument: any): Blob => {
        console.log('en el parser');
        console.log(agreementdocument);
        const file = new Blob(
            [agreementdocument],
            { type: 'application/pdf' });
        return file
    };

    parseAllSigners = (roles: PackageRole[] | undefined): Signer[] => {
        var signers: Signer[] = [];
        if (roles === undefined) {
            return signers
        }

        roles.forEach(role => {
            role.signers.forEach(signer => {
                signers.push(signer);
            })
        });
        return signers;
    }

    parseAllAttach = (roles: PackageRole[] | undefined): AttachmentRequirement[] => {
        var attachs: AttachmentRequirement[] = [];
        if (roles === undefined) {
            return attachs
        }

        roles.forEach(role => {
            role.attachmentRequirements.forEach(attach => {
                attachs.push(attach);
            })
        });
        return attachs;
    }



    getBase64 = (file: any) => {
        console.log(file);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            console.log(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    arrayBufferToBase64 = (buffer: any) => {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    //Parse Agreement info by Package OSS

    parseAgreementPackage = (agreementPackage: OnboardingAgreementPackageAPI): OnboardingAgreementPackage => {
        return {
            created: agreementPackage.created,
            id: agreementPackage.id,
            name: agreementPackage.name,
            roles: agreementPackage.roles.map(this.parseAgreementRole),
            updated: agreementPackage.updated,
            documents: agreementPackage.documentDto.map(this.parseAgreementDocument)

        }
    };

    parseTaskAgreementPackage = (agreementPackage: TaskAgreementPackageAPI): TaskAgreementPackage => {
        return {
            created: agreementPackage.created,
            id: agreementPackage.id,
            name: agreementPackage.name,
            roles: agreementPackage.roles.map(this.parseAgreementRole),
            updated: agreementPackage.updated,
            documents: agreementPackage.documentDto.map(this.parseAgreementDocument)

        }
    };

    parseAgreementDocument = (documentAgreement: DocumentAgreementAPI): DocumentAgreement => {
        return {
            description: documentAgreement.description,
            id: documentAgreement.id,
            approvals: documentAgreement.approvals.map(this.parseApprovals),
            name: documentAgreement.name
        }

    }

    parseFileBase64 = (attach: AttachmentBase64API): AttachmentBase64 => {
        return {
            contentType: attach.contentType,
            base64Data: attach.base64Data,
            fileName: attach.fileName
        }

    }

    parseApprovals = (documentApprovals: ApprovalAPI): Approval => {
        return {
            role: documentApprovals.role,
            id: documentApprovals.id,
            enforceCaptureSignature: documentApprovals.enforceCaptureSignature,
            accepted: documentApprovals.accepted,
            signed: documentApprovals.signed,
            disabled: documentApprovals.disabled,
            optional: documentApprovals.optional,
            name: documentApprovals.name
        }

    }

    parseAgreementRole = (agreementRole: PackageRoleAPI): PackageRole => {
        return {
            id: agreementRole.id,
            deliverDocumentsByEmail: agreementRole.deliverDocumentsByEmail,
            attachmentRequirements: agreementRole.attachmentRequirements,
            locked: agreementRole.locked,
            type: agreementRole.type,
            signers: agreementRole.signers.map(this.parseAgreementSigner),
            name: agreementRole.name,
        }
    };

    parseAgreementSigner = (signer: SignerAPI): Signer => {
        return {
            address: signer.address,
            company: signer.company,
            created: signer.created,
            email: signer.email,
            name: signer.firstName + ' ' + signer.lastName,
            firstName: signer.firstName,
            language: signer.language,
            lastName: signer.lastName,
            signature: this.parseSignature(signer.signature),
            updated: signer.updated,
            id: signer.id,
            signerType: signer.signerType,
            auth: this.parseAuth(signer.auth)
        }
    };

    parseSignature = (signature: SignatureAPI): Signature => {
        return {
            textual: "",
            handdrawn: ""
        }
    }

    parseAuth = (auth: AuthAPI): Auth => {
        return {
            scheme: auth.scheme,
            challenges: auth.challenges
        }
    }


    formatFlowStatus = (value: OnboardingStatus): JSX.Element =>
        <span className={"label label-" + (value === OnboardingStatus.END_FAIL ? "danger" :
            value === OnboardingStatus.END_OK ? "success" : "info")}>
            {this.res.translateEnum(value)}
        </span>;



    formatStepStatusAsAlert = (value: StepStatus): JSX.Element =>
        <div className={`alert alert-bold alert-${value === StepStatus.VERIFICATION_FAILED ? "danger" :
            value === StepStatus.DECLINED ? "danger" :
                value === StepStatus.EXPIRED ? "danger" :
                    value === StepStatus.ARCHIVED ? "danger" :
                        value === StepStatus.COMPLETED ? "success" : "warning"}`}>
            <i className={`fas fa-${value === StepStatus.COMPLETED ? 'check-circle' :
                value === StepStatus.TIMEOUT ? 'times-circle' : 'exclamation-triangle'}`}></i>
            {this.res.translateEnum(value).toUpperCase()}
        </div>;

    formatStepStatusAsTitle = (value: StepStatus, number: number): JSX.Element =>
        <div className="instance-flow-status">
            <span className={"label label-default"}>{number}</span>
            <span className={"label label-status label-" + (value === StepStatus.VERIFICATION_FAILED ? "danger" :
                value === StepStatus.DECLINED ? "danger" :
                    value === StepStatus.EXPIRED ? "danger" :
                        value === StepStatus.ARCHIVED ? "danger" :
                            value === StepStatus.COMPLETED ? "success" : "warning")}>
                &ensp;
            </span>
        </div>;

    formatFlowStatusAsAlert = (value: OnboardingStatus | TaskStatus): JSX.Element =>
        <div className={`alert alert-bold alert-${value === OnboardingStatus.END_OK || value === TaskStatus.END_OK ? 'success' :
            value === OnboardingStatus.END_FAIL || value === TaskStatus.END_FAIL? 'danger' : 'info'}`}>
            <i className={`fas fa-${value === OnboardingStatus.END_OK || value === TaskStatus.END_OK ? 'check-circle' :
                value === OnboardingStatus.END_FAIL || value === TaskStatus.END_FAIL ? 'times-circle' : 'info-circle'}`}></i>
            {this.res.translateEnum(value).toUpperCase()}
        </div>;


    formatStepStatus = (value: StepStatus): JSX.Element =>
        <span className={"label label-status label-" + (value === StepStatus.VERIFICATION_FAILED ? "danger" :
            value === StepStatus.DECLINED ? "danger" :
                value === StepStatus.EXPIRED ? "danger" :
                    value === StepStatus.ARCHIVED ? "danger" :
                        value === StepStatus.COMPLETED ? "success" : "warning")}>
            {this.res.translateEnum(value)}
        </span>;

    formatResult = (value: string): JSX.Element =>
        <span className={"label label-status label-" + (value === "no" ? "danger" :
            value === "yes" ? "success" : "warning")}>
            {(value === "data_not_supplied" ? this.res.translateEnum(value.toUpperCase()) : this.res.translate("verified-" + value))}
        </span>;

    formatBoolean = (value: Boolean): JSX.Element =>
        <span className={"label label-status label-" + (value === true ? "warning" :
            value === false ? "success" : "danger")}>
            {(value === true ? this.res.translate("verified-true") : value === false ? this.res.translate("verified-false") : this.res.translate("null"))}
        </span>;

    formatStatusResult = (value: Status): JSX.Element =>
        <span className={"label label-status label-" + (value.result === "no" ? "danger" :
            value.result === "yes" ? "success" : "warning")}>
            {(value.result === "data_not_supplied" ? this.res.translateEnum(value.result.toUpperCase()) : this.res.translate("verified-" + value.result))}

        </span>;

    formatStatusAttach = (value: AttachReqStatus): JSX.Element =>
        <span className={"label label-status label-" + (value === AttachReqStatus.REJECTED ? "danger" :
            value === AttachReqStatus.COMPLETE ? "success" : "warning")}>
            {this.res.translateEnum(value)}
        </span>;

    formatStatusComment = (value: Status): string =>
        value.comment;

    formatCreationDate = (value: { [key: string]: Date }): JSX.Element =>
        <span> {value["created"]}</span>;

    formatLastEventDate = (value: { [key: string]: Date }): JSX.Element =>
        <span> {value["last_event"]}</span>;


}
