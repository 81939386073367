import { AppContextProps, withAppContext } from "@identity/context";
import { IdentityValidation, Notifications } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router-dom";

export interface NotificationsAgreementListProps extends RouteComponentProps, AppContextProps {
    notifications: Notifications[];
    tableOptions: any;
  
}
export interface NotificationsAgreementListState {
    
}

class NotificationsAgreementList extends React.Component<NotificationsAgreementListProps, NotificationsAgreementListState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: NotificationsAgreementListProps) {
        super(props);
        this.state = {
            
        };
        this.props.configureClass(this.obParser);
    }

    render = (): ReactNode => (
        <BootstrapTable
            data={this.props.notifications}
            
            options={this.props.tableOptions}
            selectRow={undefined}>
            <TableHeaderColumn isKey={true} width="50" dataAlign="center" dataField='id' dataSort>#</TableHeaderColumn>
            <TableHeaderColumn dataField='onboardingApplicationId' width="150" dataAlign="center" dataSort >{this.props.resources.translate('package-id')}</TableHeaderColumn>
            <TableHeaderColumn dataField='createdDate' width="160" dataAlign="center" dataSort>{this.props.resources.translate('date')}</TableHeaderColumn>
            <TableHeaderColumn dataField='name' dataAlign="center" width="270" dataSort >{this.props.resources.translate('operation-trigger')}</TableHeaderColumn>
            <TableHeaderColumn dataField='sessionUser' width="150" dataAlign="center" dataSort >{this.props.resources.translate('session-user')}</TableHeaderColumn>
            <TableHeaderColumn dataField='message' dataAlign="center" dataSort >{this.props.resources.translate('message-details')}</TableHeaderColumn>
        </BootstrapTable>
    )
}

export default withAppContext(NotificationsAgreementList);