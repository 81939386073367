import { AppContextProps, withAppContext } from "@identity/context";
import { TaskApplicationFlow } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router-dom";

export interface TaskJointListProps extends RouteComponentProps, AppContextProps {
    taskApplicationFlows: TaskApplicationFlow[];
    tableOptions: any;
    handleSelected?: (selected: TaskApplicationFlow) => void;
}
export interface TaskJointListState {
    selected: TaskApplicationFlow | undefined;
}

class TaskJointList extends React.Component<TaskJointListProps, TaskJointListState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: TaskJointListProps) {
        super(props);
        this.state = {
            selected: undefined,
        };
        this.props.configureClass(this.obParser);
    }

    handleSelected = (selected: TaskApplicationFlow): void => {
        this.setState({ selected });
        if (this.props.handleSelected)
            this.props.handleSelected(selected);
    }

    render = (): ReactNode => (
        <BootstrapTable
            data={this.props.taskApplicationFlows}
            options={this.props.tableOptions}
            selectRow={this.props.handleSelected ? { mode: 'radio', onSelect: this.handleSelected } : { mode: 'none' }}>
            <TableHeaderColumn dataField='id' isKey width="160">{this.props.resources.translate('id-list')}</TableHeaderColumn>
            <TableHeaderColumn dataField='actionType' dataAlign="center" dataSort dataFormat={this.props.resources.translateEnum}>{this.props.resources.translate('action')}</TableHeaderColumn>
            <TableHeaderColumn dataField='jointAccountType' dataAlign="center" dataSort>{this.props.resources.translate('joint-account')}</TableHeaderColumn>    
            <TableHeaderColumn dataField='cellphone' dataAlign="center" dataSort>{this.props.resources.translate('phone-mobile')}</TableHeaderColumn>
            <TableHeaderColumn dataField='createDate' dataAlign="center" dataSort>{this.props.resources.translate('date-creation')}</TableHeaderColumn>
            <TableHeaderColumn dataField='taskStatus' dataAlign="center" dataSort dataFormat={this.obParser.formatFlowStatus}>{this.props.resources.translate('status')}</TableHeaderColumn>
        </BootstrapTable>
    )
}

export default withAppContext(TaskJointList);