import React, { ReactNode } from "react";
import { RouteComponentProps, Switch, Route } from "react-router";
import { Link } from "react-router-dom";
import { MAIN_ROUTE } from "@curus/routing";
import { AppContextProps, withAppContext } from "@identity/context";
import { CURUS_ON_BOARDING_DOCUMENT_VALIDATION_ROUTE, CURUS_ON_BOARDING_FLOW_ROUTE, CURUS_ON_BOARDING_ROUTE } from "@identity/routing-on-boarding";
import { USER_MAIN_ROUTE, USER_CREATE_ROUTE, USER_UPDATE_ROUTE, USER_SEARCH_ROUTE } from "@identity/routing-person";
import { CURUS_ON_BOARDING_PORTAL_FLOW_ROUTE, CURUS_ON_BOARDING_PORTAL_ROUTE } from "routing";

export interface NavLinksProps extends RouteComponentProps, AppContextProps { }
export interface NavLinksState { }

class NavLinks extends React.Component<NavLinksProps, NavLinksState> {
    route = (props: RouteComponentProps): string => {
        return this.props.resources.route(props.match.path, props.match.params);
    }

    render = (): ReactNode => (
        <Switch>
            <Route path={MAIN_ROUTE} exact component={() => <li className="active">{this.props.config.app.name}</li>} />
            <Route path={MAIN_ROUTE} render={(props: RouteComponentProps) => ([
                <li key="curus-identity-0" className="active"><Link to={this.route(props)}><i className="fas fa-home"></i></Link></li>,
                <Switch key="curus-identity-1">
                    <Route path={USER_SEARCH_ROUTE} exact component={() => <li className="active">{this.props.resources.translate('user-manage')}</li>} />
                    <Route path={USER_MAIN_ROUTE} exact component={() => <li className="active">{this.props.resources.translate('user-manage')}</li>} />
                    <Route path={USER_MAIN_ROUTE} render={(props) => ([
                        <li key="b-0-0"><Link to={this.route(props)}>{this.props.resources.translate('user-manage')}</Link></li>,
                        <Switch key="b-0-1">
                            <Route path={USER_CREATE_ROUTE} exact component={() => <li className="active">{this.props.resources.translate('create')}</li>} />
                            <Route path={USER_UPDATE_ROUTE} exact component={() => <li className="active">{this.props.resources.translate('modify')}</li>} />
                        </Switch>
                    ])} />

                    <Route path={CURUS_ON_BOARDING_ROUTE} exact component={() => <li className="active">{this.props.resources.translate('curus-on-boarding')}</li>} />
                    <Route path={CURUS_ON_BOARDING_ROUTE} render={(props) => ([
                        <li key="ca-0-0"><Link to={this.route(props)}>{this.props.resources.translate('curus-on-boarding')}</Link></li>,
                        <Switch key="ca-0-1">
                            <Route path={CURUS_ON_BOARDING_FLOW_ROUTE} exact component={() => <li className="active">{this.props.resources.translate('flow')}</li>} />
                            <Route path={CURUS_ON_BOARDING_DOCUMENT_VALIDATION_ROUTE} exact component={() => <li className="active">{this.props.resources.translate('document-validation-result')}</li>} />
                        </Switch>
                    ])} />
                    <Route path={CURUS_ON_BOARDING_PORTAL_ROUTE} exact component={() => <li className="active">{this.props.resources.translate('curus-task')}</li>} />
                    <Route path={CURUS_ON_BOARDING_PORTAL_ROUTE} render={(props) => ([
                        <li key="ca-0-0"><Link to={this.route(props)}>{this.props.resources.translate('curus-task')}</Link></li>,
                        <Switch key="ca-0-1">
                            <Route path={CURUS_ON_BOARDING_PORTAL_FLOW_ROUTE} exact component={() => <li className="active">{this.props.resources.translate('flow')}</li>} />
                        </Switch>
                    ])} />
                </Switch>
            ])} />
        </Switch>
    )
}

export default withAppContext(NavLinks);
