import { AppContextProps, withAppContext } from "@identity/context";
import React, { ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";
import ValidationIdentityList from "components/on-boarding/flow/validation/identity/list"
import { IdentityValidation } from "interfaces";
import ValidationIdentity from "components/on-boarding/flow/validation/identity/info"

export interface StepIdentityValidationsProps extends RouteComponentProps, AppContextProps {
    validations: IdentityValidation[];
    tableOptions: any;
}
export interface StepIdentityValidationsState {
    selected: IdentityValidation | undefined;
}

export class StepIdentityValidations<P extends StepIdentityValidationsProps, S extends StepIdentityValidationsState> extends React.Component<P, S> {

    constructor(props: P) {
        super(props);
        this.state = {
            selected: undefined,
        } as S;

    }

    handleSelectedValidation = (selected: IdentityValidation): void => {
        this.setState({ selected });
    }



    render = (): ReactNode => {
        return (
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{this.props.resources.translate('validations')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="panel fresh-color panel-info">
                            <div className="panel-heading">
                                <div className="title">{this.props.resources.translate('select-identity-validation')}</div>
                            </div>
                            <ValidationIdentityList validations={this.props.validations}
                                tableOptions={this.props.tableOptions}
                                handleSelected={this.handleSelectedValidation} />
                        </div>
                        {this.state.selected && <div className="panel fresh-color panel-info">
                            <div className="panel-heading">
                                <div className="title">{this.props.resources.translate('validation')}</div>
                            </div>
                            <ValidationIdentity validation={this.state.selected}
                                tableOptions={this.props.tableOptions} />
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default withAppContext(StepIdentityValidations);