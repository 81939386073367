import { Formatter } from "@curus/utils";
import { IdentityConfig, IdentityResources } from "@identity/classes";
import { AppContextProps, withAppContext } from "@identity/context";
import { StepFlow } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";
import StepIdentityValidations from "components/on-boarding/flow/step/identity"

export interface StepIdentityInfoProps extends RouteComponentProps, AppContextProps {
    step: StepFlow;
    tableOptions: any;
}
export interface StepIdentityInfoState {
    tableOptions: any;
    stepDetail: JSX.Element | undefined;
}

class StepIdentityInfo extends React.Component<StepIdentityInfoProps, StepIdentityInfoState> {
    protected formatter: Formatter<IdentityConfig, IdentityResources> = new Formatter();
    protected obParser: OnBoardingParser = new OnBoardingParser();


    constructor(props: StepIdentityInfoProps) {
        super(props);
        this.state = {
            stepDetail: undefined,
            tableOptions: {
                noDataText: this.props.resources.translate('table-empty'),
            },
        };
        this.props.configureClass(this.formatter, this.obParser);
    }

    render = (): ReactNode => (
        <div>
            <div className="col-md-6">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{this.props.resources.translate('validation-info')}</div>
                        </div>
                    </div>
                    {this.props.step && <div className="card-body">
                        <table className="table table-custom">
                            <tbody className="data">
                                {this.props.step.createDate && <tr>
                                    <th className="col-lg-3">{this.props.resources.translate('date-creation')}:</th>
                                    <td colSpan={3}>{this.props.step.createDate.toString()}</td>
                                </tr>}
                                {this.props.step.provider && <tr>
                                    <th className="col-lg-3">{this.props.resources.translate('provider')}:</th>
                                    <td colSpan={3}>{this.props.step.provider}</td>
                                </tr>}
                                {this.props.step.status && <tr>
                                    <th className="col-lg-3">{this.props.resources.translate('status')}:</th>
                                    <td colSpan={3}>{this.obParser.formatStepStatus(this.props.step.status)}</td>
                                </tr>}
                                {this.props.step.type && <tr>
                                    <th className="col-lg-3">{this.props.resources.translate('type')}:</th>
                                    <td colSpan={3}> {this.props.resources.translateEnum(`VALIDATION_TYPE_${this.props.step.type}`)}</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
            <StepIdentityValidations validations={this.props.step.details}
                tableOptions={this.state.tableOptions}
                handleSelected />
            
        </div>



    )
}

export default withAppContext(StepIdentityInfo);