import React, { ReactNode } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import NavBar from '@curus/nav-bar';
import { UserNavPanel } from "@identity/components-person";
import { AppContextProps, withAppContext } from "@identity/context";
import { LOGIN_ROUTE } from "@curus/routing";
import { USER_MAIN_ROUTE } from "@identity/routing-person";
import { PERSON_GROUP_PARAM, PERSON_ID_PARAM } from "@identity/routing-person/param";
import { USER_GET_PERM } from "@identity/routing-person/permission";
import SessionServices from "@identity/services-session";
import NavLinks from "views/nav-bar/nav-links";

export interface KimnNavBarProps extends RouteComponentProps, AppContextProps {
    handleCollapse: () => void;
};
export interface KimnNavBarState { };

class KimnNavBar extends React.Component<KimnNavBarProps, KimnNavBarState> {
    protected sServices: SessionServices = new SessionServices();

    constructor(props: KimnNavBarProps) {
        super(props);
        this.props.configureClass(this.sServices);
    }

    logout = (): void => {
        this.props.showLoading(true);
        this.sServices
            .logout()
            .then(this.handleLogout)
            .catch(this.handleLogout);
    }

    handleLogout = (): void => {
        this.props.showLoading(false);
        this.sServices.clearBearerToken();
        if (this.props.config.webSocket.enabled) {
            this.props.webSocket.disconnectWebSocket();
        }
        this.props.history.replace(LOGIN_ROUTE);
    }

    render = (): ReactNode => (
        <NavBar handleCollapse={this.props.handleCollapse}
            navLinks={<NavLinks />}>
            {this.props.principal && <UserNavPanel>
                {this.props.haveOrPermission(USER_GET_PERM) && <Link to={USER_MAIN_ROUTE.replace(PERSON_GROUP_PARAM, this.props.principal.group).replace(PERSON_ID_PARAM, this.props.principal.username)} className="btn btn-default">
                    <i className="fas fa-person"></i>{this.props.resources.translate('profile')}
                </Link>}
                <button onClick={this.logout} className="btn btn-default" style={{ marginLeft: '12px' }}><i className="fas fa-sign-out"></i>{this.props.resources.translate('logout')}</button>
            </UserNavPanel>}
        </NavBar>
    )
}

export default withAppContext(KimnNavBar);
