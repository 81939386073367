export interface OnboardingApplicationFlowAPI {
    id: string;
    clarienProduct: ClarienProduct;
    cellphone: string;
    onboardingStatus: OnboardingStatus;
    startDatetime: number;
    machineSecret?: MachineSecretAPI;
    ipClient?: string;
    stepFlows: StepFlowAPI[];
    type: string;
    jointAccountType: string;
}

export interface OnboardingApplicationFlow {
    id: string;
    clarienProduct: ClarienProduct;
    cellphone: string;
    onboardingStatus: OnboardingStatus;
    createDate: Date;
    machineSecret?: MachineSecret;
    ipClient?: string;
    steps: StepFlow[];
    type: string;
    jointAccountType: string;
}

export interface TaskApplicationFlowAPI {
    id: string;
    cellphone: string;
    startDatetime: number;
    stepFlows: StepFlowAPI[];
    taskStatus: TaskStatus;
    actionType: ActionType;
    jointAccountType: string;
    type: string;

}

export interface TaskApplicationFlow {
    id: string;
    cellphone: string;
    createDate: Date;
    steps: StepFlow[];
    taskStatus: TaskStatus;
    actionType: ActionType;
    jointAccountType: string;
    type: string;
}


export interface MachineSecretAPI {
    applicationData: {
        deviceName: string;
        platform: string;
        osVersion?: string;
        sdkVersion?: string;
        appVersion?: string;
    }
}

export interface StepFlowAPI {
    status: StepStatus;
    creationDate: number;
    type: ValidationType;
    provider: OsProvider;
    detailValidations: any[];
}

export interface StepFlow {
    status: StepStatus;
    createDate: Date;
    type: ValidationType;
    provider: OsProvider;
    details: any[];
}

export interface MachineSecret {
    deviceName: string;
    platform: string;
    osVersion?: string;
    sdkVersion?: string;
    appVersion?: string;
}

export interface ValidationAPI {
    dates: { [key: string]: Date };
    matches: Match[];
    status: Status;

}

export interface Validation {
    index: number;
    dates: { [key: string]: Date };
    matches: Match[];
    status: Status;

}

export interface Match {
    result: string;
    matchedData: string;
    dataExtracted: string;
    dataSubmitted: string;
    comment: string;
}

export interface Status {
    result: string;
    comment: string;

}

export interface IdentityValidation extends Validation {
    biometryScores: { [key: string]: number };
    images: { [key: string]: string };
    documentScores: { [key: string]: number };
    documentfields: DocumentField[];
}

export interface IdentityValidationAPI extends ValidationAPI {
    biometryScores: { [key: string]: number };
    images: { [key: string]: string };
    documentScores: { [key: string]: number };
    documentfields: DocumentField[];
}

export interface OtpValidationAPI extends ValidationAPI {
    verifications: VerificationOtp[];
    contact: string;
    deliveryType: DeliveryType;

}

export interface OtpValidation extends Validation {
    verifications: VerificationOtp[];
    contact: string;
    deliveryType: DeliveryType;

}

export interface VerificationOtp {
    date: Date;
    status: Status;
    remainingAttempts: number;

}

export interface DocumentField {
    fieldName: string;
    name: string;
    text: string;
}

export enum DeliveryType {
    NONE = "NONE",
    SMS = "SMS",
    VOICE = "VOICE",
    EMAIL = "EMAIL"
}

export enum OsProvider {
    ONESPAN = "ONESPAN",
    VERIDAS = "VERIDAS",
    OTHER = "OTHER"
}

export enum ValidationType {
    OTP = "OTP",
    IDENTITY = "IDENTITY",
    AGREEMENT = "AGREEMENT",
    UNKNOWN = "UNKNOW"
}

export enum StepStatus {
    NEW = "NEW",
    COMPLETED = "COMPLETED",
    VERIFICATION_FAILED = "VERIFICATION_FAILED",
    TIMEOUT = "TIMEOUT",
    UNKNOWK = "UNKNOWK",
    DRAFT = "DRAFT",
    SENT = "SENT",
    ARCHIVED = "ARCHIVED",
    DECLINED = "DECLINED",
    OPTED_OUT = "OPTED_OUT",
    EXPIRED = "EXPIRED"

}

export enum OnboardingStatus {
    INITIAL = "INITIAL",
    ID_VERIFICATION_CREATED = "ID_VERIFICATION_CREATED",
    ID_VERIFICATION_OK = "ID_VERIFICATION_OK",
    ID_VERIFICATION_FAIL = "ID_VERIFICATION_FAIL",
    FORM_WAIT = "FORM_WAIT",
    AGREEMENT_CREATED = "AGREEMENT_CREATED",
    AGREEMENT_OK = "AGREEMENT_OK",
    WAITING_SIGNATURE = "WAITING_SIGNATURE",
    AGREEMENT_SIGNED = "AGREEMENT_SIGNED",
    INTERNAL_POSTED = "INTERNAL_POSTED",
    INTERNAL_OK = "INTERNAL_OK",
    INTERNAL_FAIL = "INTERNAL_FAIL",
    END_OK = "END_OK",
    END_FAIL = "END_FAIL",
    EMAIL_SENT = "EMAIL_SENT",
    AGREEMENT_PROCESSED = "AGREEMENT_PROCESSED",
    PROCESS_OSS_QUEUE = "PROCESS_OSS_QUEUE"
}

export enum TaskStatus {
    INITIAL = "INITIAL",
    ID_VERIFICATION_CREATED = "ID_VERIFICATION_CREATED",
    ID_VERIFICATION_OK = "ID_VERIFICATION_OK",
    ID_VERIFICATION_FAIL = "ID_VERIFICATION_FAIL",
    FORM_WAIT = "FORM_WAIT",
    AGREEMENT_CREATED = "AGREEMENT_CREATED",
    AGREEMENT_OK = "AGREEMENT_OK",
    AGREEMENT_WAITING_SIGNATURE = "AGREEMENT_WAITING_SIGNATURE",
    AGREEMENT_SIGNED = "AGREEMENT_SIGNED",
    INTERNAL_POSTED = "INTERNAL_POSTED",
    INTERNAL_OK = "INTERNAL_OK",
    INTERNAL_FAIL = "INTERNAL_FAIL",
    END_OK = "END_OK",
    END_FAIL = "END_FAIL",
    EMAIL_SENT = "EMAIL_SENT",
}

export enum ClarienProduct {
    SAVINGS = "SAVINGS",
    CHEQUING = "CHEQUING",
    INVEST = "INVEST",
    INVEST_SAVINGS = "INVEST_SAVINGS",
    INVEST_CHEQUING = "INVEST_CHEQUING",
    UNKNOWN = "UNKNOWN"
}

export enum ActionType {
    CHANGE_ADDRESS = "CHANGE_ADDRESS",
    CHANGE_NAME = "CHANGE_NAME",
    UPDATE_PASSPORT = "UPDATE_PASSPORT",
    UNBLOCK_ACCOUNT = "UNBLOCK_ACCOUNT",
    REQUEST_DEBIT_CARD = "REQUEST_DEBIT_CARD",
    ADD_ACCOUNT_HOLDER = "ADD_ACCOUNT_HOLDER",
    REMOVE_ACCOUNT_HOLDER = "REMOVE_ACCOUNT_HOLDER",
    CLOSE_JOINT_ACCOUNT = "CLOSE_JOINT_ACCOUNT",
    CLOSE_SOLE_ACCOUNT = "CLOSE_SOLE_ACCOUNT",
    INCREASE_DEBIT_CARD_LIMIT = "INCREASE_DEBIT_CARD_LIMIT",
    UNKNOWN = "UNKNOWN"

}

export interface PaginatedResultAPI<T> {
    content: T[];
    empty: boolean;
    first: boolean;
    last: boolean;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export interface PaginatedResult<T> {
    content: T[];
    empty: boolean;
    first: boolean;
    last: boolean;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

/** Entradas */
export interface SearchFilterAPI {
    dateInit?: Date | number;
    dateFinish?: Date | number;
    filter?: object;
}


export interface ListValidationOtp {
    index: number;
    result: string;
    comment: string;
    creationDate: Date;
    lastEventDate: Date;
    dates: { [key: string]: Date };
    matches: Match[];
    verifications: VerificationOtp[];
    contact: string;
    deliveryType: DeliveryType;
    status: Status;

}
export interface ListValidationIdentity {
    index: number;
    result: string;
    comment: string;
    creationDate: Date;
    lastEventDate: Date;
    dates: { [key: string]: Date };
    matches: Match[];
    images: { [key: string]: string };
    biometryScores: { [key: string]: number };
    documentScores: { [key: string]: number };
    documentfields: DocumentField[];
    status: Status;
}

export interface AttachmentBase64API {
    contentType: string;
    base64Data: string;
    fileName: string;
}

export interface AttachmentBase64 {
    contentType: string;
    base64Data: string;
    fileName: string;
}

export interface AgreementAuditEvent {
    targetType: string;
    dateTime: string;
    type: string;
    target: string;
    user: string;
    userEmail: string;
    userIp: string;
    data: string;
}


export interface OnboardingAgreementPackageAPI {
    created: Date;
    id: string;
    name: string;
    roles: PackageRoleAPI[];
    updated: Date;
    documentDto: DocumentAgreementAPI[];
}

export interface TaskAgreementPackageAPI {
    created: Date;
    id: string;
    name: string;
    roles: PackageRoleAPI[];
    updated: Date;
    documentDto: DocumentAgreementAPI[];
}

export interface AgrementPdf {
    data: object;
    base64: string | undefined;
}

export interface OnboardingAgreementPackage {
    created: Date;
    id: string;
    name: string;
    roles: PackageRole[];
    updated: Date;
    documents: DocumentAgreement[];
}

export interface TaskAgreementPackage {
    created: Date;
    id: string;
    name: string;
    roles: PackageRole[];
    updated: Date;
    documents: DocumentAgreement[];
}

export interface DocumentAgreementAPI {
    description: string;
    id: string;
    approvals: ApprovalAPI[];
    name: string;

}

export interface DocumentAgreement {
    description: string;
    id: string;
    approvals: Approval[];
    name: string;
}

export interface ApprovalAPI {
    role: string;
    id: string;
    enforceCaptureSignature: boolean;
    accepted: Date;
    signed: Date;
    disabled: boolean;
    optional: boolean;
    name: string;
}

export interface Approval {
    role: string;
    id: string;
    enforceCaptureSignature: boolean;
    accepted: Date;
    signed: Date;
    disabled: boolean;
    optional: boolean;
    name: string;
}

export interface PackageRoleAPI {
    id: string;
    deliverDocumentsByEmail: boolean;
    attachmentRequirements: AttachmentRequirementAPI[];
    locked: boolean;
    type: RoleType;
    signers: SignerAPI[];
    name: string;
}

export interface SignerAPI {
    address: string;
    company: string;
    created: Date;
    email: string;
    firstName: string;
    language: string[];
    lastName: string;
    signature: SignatureAPI;
    updated: Date;
    id: string;
    signerType: SignerType;
    auth: AuthAPI;
}

export interface Signer {
    address: string;
    company: string;
    created: Date;
    email: string;
    name: string;
    firstName: string;
    language: string[];
    lastName: string;
    signature: Signature;
    updated: Date;
    id: string;
    signerType: SignerType;
    auth: Auth;
}

export interface SignatureAPI {
    textual: string;
    handdrawn: string;
}

export interface Signature {
    textual: string | undefined;
    handdrawn: string;
}


export interface AuthAPI {
    scheme: Scheme;
    challenges: Challengue[];
}

export interface Auth {
    scheme: Scheme;
    challenges: Challengue[];
}

export interface ChallengueAPI {
    question: string;
    answer: string;
    maskInput: boolean;
}

export interface Challengue {
    question: string;
    answer: string;
    maskInput: boolean;
}

export interface PackageRole {
    id: string;
    deliverDocumentsByEmail: boolean;
    attachmentRequirements: AttachmentRequirement[];
    locked: boolean;
    type: RoleType;
    signers: Signer[];
    name: string;
}

export interface AttachmentRequirementAPI {
    comment: string;
    description: string;
    required: boolean;
    status: AttachReqStatus;
    files: FileAttachAPI[];
    id: string;
    name: string;

}

export interface AttachmentRequirement {
    comment: string;
    description: string;
    required: boolean;
    status: AttachReqStatus;
    files: FileAttach[];
    id: string;
    name: string;

}

export interface FileAttachAPI {
    id: string;
    insertDate: Date;
    name: string;
    preview: boolean;
}


export interface FileAttach {
    id: string;
    insertDate: Date;
    name: string;
    preview: boolean;
}

export interface NotificationsAPI {
    id: string;
    onboardingApplicationId: string;
    type: string;
    jsonContent: string;
}

export interface Notifications {
    id: string;
    onboardingApplicationId: string;
    timestamp: string;
    trigger: string;
    message: string;
    name: string;
    sessionUser: string;
    createdDate: string

}


export enum RoleType {
    SIGNER = "SIGNER",
    SENDER = "SENDER"
}

export enum AttachReqStatus {
    INCOMPLETE = "INCOMPLETE",
    REJECTED = "REJECTED",
    COMPLETE = "COMPLETE"
}

export enum SignerType {
    ACCOUNT_SENDER = "ACCOUNT_SENDER",
    EXTERNAL_SENDER = "EXTERNAL_SENDER",
    EXTERNAL_SIGNER = "EXTERNAL_SIGNER",
    GROUP_SIGNER = "GROUP_SIGNER"
}

export enum Scheme {
    NONE = "NONE",
    PROVIDER = "PROVIDER",
    CHALLENGE = "CHALLENGE",
    SMS = "SMS",
    SSO = "SSO",
    KBA = "KBA"
}

