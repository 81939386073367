import { AppContextProps, withAppContext } from "@identity/context";
import { IdentityValidation } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router-dom";

export interface ValidationIdentityListProps extends RouteComponentProps, AppContextProps {
    validations: IdentityValidation[];
    tableOptions: any;
    handleSelected?: (selected: IdentityValidation) => void;
}
export interface ValidationIdentityListState {
    selected: IdentityValidation | undefined;
}

class ValidationIdentityList extends React.Component<ValidationIdentityListProps, ValidationIdentityListState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: ValidationIdentityListProps) {
        super(props);
        this.state = {
            selected: undefined,
        };
        this.props.configureClass(this.obParser);
    }

    handleSelected = (selected: IdentityValidation): void => {
        this.setState({ selected });
        if (this.props.handleSelected)
            this.props.handleSelected(selected);
    }

    render = (): ReactNode => (
        <BootstrapTable
            data={this.props.validations}
            options={this.props.tableOptions}
            selectRow={this.props.handleSelected ? { mode: 'radio', onSelect: this.handleSelected } : { mode: 'none' }}>
            <TableHeaderColumn isKey={true} width="50" dataAlign="center" dataField='index' dataSort>#</TableHeaderColumn>
            <TableHeaderColumn dataField='dates' dataAlign="center" dataSort dataFormat={this.obParser.formatCreationDate}>{this.props.resources.translate('date-creation')}</TableHeaderColumn>
            <TableHeaderColumn dataField='dates' dataAlign="center" dataSort dataFormat={this.obParser.formatLastEventDate}>{this.props.resources.translate('date-last-event')}</TableHeaderColumn>
            <TableHeaderColumn dataField='status' dataAlign="center" dataSort dataFormat={this.obParser.formatStatusResult}>{this.props.resources.translate('result')}</TableHeaderColumn>
            <TableHeaderColumn dataField='status' tdStyle={ { whiteSpace: 'normal' } }  dataAlign="center" dataSort dataFormat={this.obParser.formatStatusComment}>{this.props.resources.translate('comment')} </TableHeaderColumn>
        </BootstrapTable>
    )
}

export default withAppContext(ValidationIdentityList);