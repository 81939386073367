import { ApiResponseError } from "@curus/api";
import { AppContextProps, withAppContext } from "@identity/context";
import { AttachmentRequirement, AttachReqStatus } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React from "react";
import { ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";
import { RouteAgreementAttachmentParams } from "routing/param";
import OnBoardingServices from "services/onboarding";

export interface AttachReqInfoProps extends RouteComponentProps, AppContextProps {
    attachReq: AttachmentRequirement;
    tableOptions: any;
    packageId: string | undefined;
}
export interface AttachReqInfoState {
    selected: AttachmentRequirement | undefined;
    params: RouteAgreementAttachmentParams;
}

class AttachReqInfo extends React.Component<AttachReqInfoProps, AttachReqInfoState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();
    protected obServices: OnBoardingServices = new OnBoardingServices();

    constructor(props: AttachReqInfoProps) {
        super(props);
        this.state = {
            selected: undefined,
            params: {
                packageId: this.props.packageId,
                attachmentId: this.props.attachReq.id
            }
        };

        this.props.configureClass(this.obParser);
        this.props.configureClass(this.obServices);
    }

    download = (): void => {
        this.props.showLoading(true);
        this.obServices
            .attachment(this.state.params)
            .then(file => {
                var a = document.createElement("a"); //Create <a>
                a.href = "data:" + file.contentType + ";base64," + file.base64Data; //Image Base64 Goes here
                a.download = file.fileName; //File name Here
                a.click(); //Downloaded file

                this.props.showLoading(false);
            })
            .catch((response: ApiResponseError) => {
                this.props.showAlert(response.message);
                this.props.showLoading(false);
            });
    }



    render = (): ReactNode => {
        const colClass: string = 'col-md-12';
        return (
            <div>
                <div className="row">
                    <div className={colClass}>
                        <div className={`card`}>
                            <div className="card-header">
                                <div className="card-title">
                                    <div className="title">{this.props.resources.translate('info-attach-doc')}</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-custom">
                                    <tbody className="data">
                                        {this.props.attachReq.id && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('ID')}:</th>
                                            <td colSpan={3}>{this.props.attachReq.id}</td>
                                        </tr>}
                                        {this.props.attachReq.name && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('name')}:</th>
                                            <td colSpan={3}>{(this.props.attachReq.name)}</td>
                                        </tr>}
                                        {this.props.attachReq.description && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('description')}:</th>
                                            <td colSpan={3}>{(this.props.attachReq.description)}</td>
                                        </tr>}
                                        {this.props.attachReq.comment && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('comment')}:</th>
                                            <td colSpan={3}>{(this.props.attachReq.comment)}</td>
                                        </tr>}
                                        <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('required')}:</th>
                                            <td colSpan={3}>{this.obParser.formatBoolean(this.props.attachReq.required)}</td>
                                        </tr>
                                        {this.props.attachReq.status && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('status')}:</th>
                                            <td colSpan={3}>{this.obParser.formatStatusAttach(this.props.attachReq.status)}</td>
                                        </tr>}
                                    </tbody>
                                </table>
                                <button type="button" id="sign" disabled={this.props.attachReq.status !== AttachReqStatus.COMPLETE} className="btn btn-primary" onClick={this.download}>{this.props.resources.translate('download')}</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

export default withAppContext(AttachReqInfo);