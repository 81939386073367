import { ApiResponseError } from "@curus/api";
import { AppContextProps } from "@identity/context";
import { PersonContextProps, withAppPersonContext } from "@identity/context-person";
import { CURUS_ON_BOARDING_FLOW_ROUTE } from "@identity/routing-on-boarding";
import { RoutePersonParams } from "@identity/routing-person/param";
import { ActionType, ClarienProduct, OnboardingApplicationFlow, OnboardingStatus, PaginatedResult, TaskApplicationFlow, TaskStatus } from "interfaces";
import React, { ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import OnBoardingServices from "services/onboarding";
import OnBoardingApplicationFlowList from "components/on-boarding/flow/list";
import  TaskApplicationFlowList  from "components/on-boarding/flow/list/tasks";
import Chart from "chart.js";
import OnBoardingFlowFilter from "components/on-boarding/flow/filter"
import { CURUS_ON_BOARDING_PORTAL_FLOW_ROUTE, CURUS_ON_BOARDING_PORTAL_JOINT_FLOW_ROUTE } from "routing";


export interface OnBoardingPortalDashboardViewProps extends RouteComponentProps<RoutePersonParams>, AppContextProps, PersonContextProps { }

export interface OnBoardingPortalDashboardViewState {
    taskApplicationFlows: TaskApplicationFlow[];
    tableOptions: any;
    step: number;
    stats: {
        finished: number;
        notFinished: number;
        total: number;
        success: number;
        failed: number;
    };
    selectedInitDate: Date;
    selectedEndDate: Date;
    cellphoneFilter: string;
    selectedSearch: Boolean;
    numberDays: number;
}

const INSTANCES_STEP: number = 0;




export class OnBoardingPortalDashboardView<P extends OnBoardingPortalDashboardViewProps, S extends OnBoardingPortalDashboardViewState> extends React.Component<P, S> {
    protected obServices: OnBoardingServices = new OnBoardingServices();
    private summaryChartRef: React.RefObject<any>;
    private productChartRef: React.RefObject<any>;

    constructor(props: P) {
        super(props);
        this.state = {
            onboardingApplicationFlows: [],
            step: INSTANCES_STEP,
            stats: {
                finished: 0,
                notFinished: 0,
                total: 0,
                success: 0,
                failed: 0,
            },
            tableOptions: {
                noDataText: this.props.resources.translate('table-empty'),
            },
            selectedInitDate: new Date(Date.now() - 86400000 * 90),
            selectedEndDate: new Date(),
            cellphoneFilter: "",
            numberDays: 30,
            selectedSearch: false

        } as unknown as S;

        this.props.configureClass(this.obServices);

        this.summaryChartRef = React.createRef();
        this.productChartRef = React.createRef();
    }

    componentDidMount = (): void => {
        this.fetchTaskApplicationFlows();
    }

    fetchTaskApplicationFlows = (): void => {
        this.props.showLoading(true);
        const now: Date = this.state.selectedEndDate;
        const initDate: Date = this.state.selectedInitDate;

        var differenceTime = now.getTime() - initDate.getTime();

        // To calculate the no. of days between two dates
        var differenceInDays = differenceTime / (1000 * 3600 * 24);
        this.setState({
            numberDays: Math.trunc(differenceInDays)
        });

        this.obServices
            .taskFlows({
                dateInit: initDate,
                dateFinish: now.getTime(),
                filter: {
                    cellphone: this.state.cellphoneFilter
                }
            })
            .then((paginatedFlow: PaginatedResult<TaskApplicationFlow>) => {
                this.setState({ taskApplicationFlows: paginatedFlow.content });
                this.props.showLoading(false);
                //implementar estadisticas
                this.computeTransactionsGraph(paginatedFlow.content);
            })
            .catch((response: ApiResponseError) => {
                this.props.showAlert(response.message);
                this.props.showLoading(false);
            });
    }

    handleShowTaskApplicationFlow = (instanceFlowId: string, type: string): void => {
        console.log('instanceflow: ');
        console.log(instanceFlowId);
        console.log('type: ');
        console.log(type);
        if (type == "SOLE") {
            const route: string = this.props.resources.route(CURUS_ON_BOARDING_PORTAL_FLOW_ROUTE, { instanceFlowId });
            this.props.history.push(route);
        }
        else {
            const route: string = this.props.resources.route(CURUS_ON_BOARDING_PORTAL_JOINT_FLOW_ROUTE, { instanceFlowId });
            this.props.history.push(route);
        }

    }

    setStep = (step: number): void => {
        this.setState({ step });
    }

    handleDateInitChange = (selectedDate: any): void => {
        console.log("handleDateInitChange: " + selectedDate);
        this.setState({ selectedInitDate: selectedDate });

    }
    handleDateEndChange = (selectedDate: any): void => {
        console.log("handleDateEndChange: " + selectedDate);
        this.setState({ selectedEndDate: selectedDate });
    }

    handleSearchSelected = (): void => {
        this.setState({ selectedSearch: true });
    }

    handleCellphoneFilter = (cellphone: any): void => {
        console.log("handleCellphoneFilter: " + cellphone.target.value);
        this.setState({ cellphoneFilter: cellphone.target.value });

    }

    handleApplySelected = (): void => {
        this.fetchTaskApplicationFlows();
        this.setState({ selectedSearch: false });
    }

    handleCancel = (): void => {
        this.setState({ selectedSearch: false });
    }

    computeTransactionsGraph = (taskFlows: TaskApplicationFlow[]): void => {
        const ordered: TaskApplicationFlow[] = taskFlows.sort((a: TaskApplicationFlow, b: TaskApplicationFlow) => a.createDate.getTime() - b.createDate.getTime());
        const success: number[] = [];
        const agreementCreated: number[] = [];
        const agreementOk: number[] = [];
        const agreementFail: number[] = [];
        const idVerificationCreated: number[] = [];
        const idVerificationOk: number[] = [];
        const idVerificationFail: number[] = [];
        const initial: number[] = [];
        const error: number[] = [];
        const other: number[] = [];
        let initialCount = 0, idVerFailCount = 0, idVerOkCount = 0, idVerCreatedCount = 0, agreeFailCount = 0, agreeOkCount = 0, agreeCreatedCount = 0, successCount = 0, errorCount = 0, otherCount = 0, max = 0;
        let gInitiail = 0, gIdVerFail = 0, gIdVerOk = 0, gIdVerCreated = 0, gAgreeFail = 0, gAgreeOk = 0, gAgreeCreated = 0, gSuccess = 0, gError = 0, gOther = 0;

        const changeAddress: number[] = [];
        const changeName: number[] = [];
        const updatePassport: number[] = [];
        const unblockAccount: number[] = [];
        const requestDebitCard: number[] = [];
        const addAccountHolder: number[] = [];
        const removeAccountHolder: number[] = [];
        const closeJointAccount: number[] = [];
        const closeSoleAccount: number[] = [];
        const increaseCardLimit: number[] = [];
        const unknown: number[] = [];
        let updatePassportCount = 0, changeNameCount = 0, changeAddressCount = 0, 
        unblockAccountCount = 0, unknownCount = 0, requestDebitCardCount = 0, 
        addAccountHolderCount = 0, removeAccountHolderCount = 0, closeJointAccountCount = 0,
        closeSoleAccountCount = 0, increaseCardLimitCount = 0, max2 = 0;

        ordered.forEach((taskFlow: TaskApplicationFlow) => {

            switch (taskFlow.taskStatus) {
                case TaskStatus.END_OK:
                    successCount++;
                    gSuccess++;
                    break;
                case TaskStatus.END_FAIL:
                    errorCount++;
                    gError++;
                    break;
                case TaskStatus.AGREEMENT_CREATED:
                    agreeCreatedCount++;
                    gAgreeCreated++;
                    break;
                case TaskStatus.AGREEMENT_OK:
                    agreeOkCount++;
                    gAgreeOk++;
                    break;
                case TaskStatus.ID_VERIFICATION_CREATED:
                    idVerCreatedCount++;
                    gIdVerCreated++;
                    break;
                case TaskStatus.ID_VERIFICATION_OK:
                    idVerOkCount++;
                    gIdVerOk++;
                    break;
                case TaskStatus.ID_VERIFICATION_FAIL:
                    idVerFailCount++;
                    gIdVerFail++;
                    break;
                case TaskStatus.INITIAL:
                    initialCount++;
                    gInitiail++;
                    break;
                default:
                    otherCount++;
                    gOther++;
                    break;
            }

            switch (taskFlow.actionType) {
                case ActionType.CHANGE_ADDRESS:
                    changeAddressCount++;
                    break;
                case ActionType.CHANGE_NAME:
                    changeNameCount++;
                    break;
                case ActionType.UPDATE_PASSPORT:
                    updatePassportCount++
                    break;
                case ActionType.UNBLOCK_ACCOUNT:
                    unblockAccountCount++;
                    break;
                case ActionType.REQUEST_DEBIT_CARD:
                    requestDebitCardCount++;
                    break;
                case ActionType.ADD_ACCOUNT_HOLDER:
                    addAccountHolderCount++;
                    break;
                case ActionType.REMOVE_ACCOUNT_HOLDER:
                    removeAccountHolderCount++;
                    break;
                case ActionType.CLOSE_JOINT_ACCOUNT:
                    closeJointAccountCount++;
                    break;
                case ActionType.CLOSE_SOLE_ACCOUNT:
                    closeSoleAccountCount++;
                    break;
                case ActionType.INCREASE_DEBIT_CARD_LIMIT:
                    increaseCardLimitCount++;
                    break;
                case ActionType.UNKNOWN:
                    unknownCount++;
                    break;

            }
        });
        success.push(successCount);
        error.push(errorCount);
        other.push(otherCount);
        agreementCreated.push(agreeCreatedCount);
        agreementOk.push(agreeOkCount);
        agreementFail.push(agreeFailCount);
        idVerificationCreated.push(idVerCreatedCount);
        idVerificationOk.push(idVerOkCount);
        idVerificationFail.push(idVerFailCount);
        initial.push(initialCount);
        max = Math.max(max, Math.max(successCount, errorCount, otherCount, agreeCreatedCount, agreeOkCount, agreeFailCount, idVerCreatedCount, idVerOkCount, idVerFailCount, initialCount));

        new Chart(this.summaryChartRef.current, {
            type: 'bar',
            options: {

                scales: {
                    yAxes: [
                        {
                            ticks: {
                                min: 0,
                                max: max + (max % 2),
                                callback: function (label: any, index: any, labels: any[]) {
                                    // when the floored value is the same as the value we have a whole number
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }

                                },
                            }
                        }
                    ]
                },
                responsive: true,
                maintainAspectRatio: false,

            },
            data: {
                labels: [""],
                datasets: [{
                    label: this.props.resources.translate('initial'),
                    backgroundColor: '#F7DC6F',
                    data: initial,
                }, {
                    label: this.props.resources.translate('id-verification-created'),
                    backgroundColor: '#2980B9',
                    data: idVerificationCreated,
                }, {
                    label: this.props.resources.translate('id-verification-fail'),
                    backgroundColor: '#3498DB',
                    data: idVerificationFail,
                }, {
                    label: this.props.resources.translate('id-verification-ok'),
                    backgroundColor: '#1ABC9C',
                    data: idVerificationOk,
                }, {
                    label: this.props.resources.translate('agreement-created'),
                    backgroundColor: '#F5B041',
                    data: agreementCreated,
                }, {
                    label: this.props.resources.translate('agreement-ok'),
                    backgroundColor: '#EB984E',
                    data: agreementOk,
                }, {
                    label: this.props.resources.translate('agreement-fail'),
                    backgroundColor: '#D35400',
                    data: agreementFail,
                }, {
                    label: this.props.resources.translate('failed'),
                    backgroundColor: '#E10101',
                    data: error,
                }, {
                    label: this.props.resources.translate('finished'),
                    backgroundColor: '#40BC44',
                    data: success,
                }, {
                    label: this.props.resources.translate('other'),
                    backgroundColor: '#BDC3C7',
                    data: other,
                }]
            },
        });


        changeAddress.push(changeAddressCount);
        changeName.push(changeNameCount);
        updatePassport.push(updatePassportCount);
        unblockAccount.push(unblockAccountCount);
        requestDebitCard.push(requestDebitCardCount);
        addAccountHolder.push(addAccountHolderCount);
        removeAccountHolder.push(removeAccountHolderCount);
        closeJointAccount.push(closeJointAccountCount);
        closeSoleAccount.push(closeSoleAccountCount);
        increaseCardLimit.push(increaseCardLimitCount);
        unknown.push(unknownCount);
        max2 = Math.max(max, Math.max(changeAddressCount, changeNameCount, 
            updatePassportCount, requestDebitCardCount, addAccountHolderCount,
            removeAccountHolderCount, closeJointAccountCount, closeSoleAccountCount,
            increaseCardLimitCount, unknownCount));

        new Chart(this.productChartRef.current, {
            type: 'bar',
            options: {
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                min: 0,
                                max: max2 + (max2 % 2),
                                callback: function (label: any, index: any, labels: any[]) {
                                    // when the floored value is the same as the value we have a whole number
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }

                                },
                            }
                        }
                    ]
                },
                responsive: true,
                maintainAspectRatio: false,
            },
            data: {
                labels: [""],
                datasets: [{
                    label: this.props.resources.translate('change-address'),
                    backgroundColor: '#F7DC6F',
                    data: changeAddress,
                }, {
                    label: this.props.resources.translate('change-name'),
                    backgroundColor: '#2980B9',
                    data: changeName,
                }, {
                    label: this.props.resources.translate('update-passport'),
                    backgroundColor: '#FF5733',
                    data: updatePassport,
                }, {
                    label: this.props.resources.translate('unblock-account'),
                    backgroundColor: '#BFC68C',
                    data: unblockAccount,
                }, {
                    label: this.props.resources.translate('request-debit-card'),
                    backgroundColor: '#962FBF',
                    data: requestDebitCard,
                }, {
                    label: this.props.resources.translate('add-account-holder'),
                    backgroundColor: '#FA7E1E',
                    data: addAccountHolder,
                }, {
                    label: this.props.resources.translate('remove-account-holder'),
                    backgroundColor: '#95463B',
                    data: removeAccountHolder,
                }, {
                    label: this.props.resources.translate('close-joint-account'),
                    backgroundColor: '#A2BEFF',
                    data: closeJointAccount,
                }, 
                {
                    label: this.props.resources.translate('close-sole-account'),
                    backgroundColor: '#FF1493',
                    data: closeSoleAccount,
                }, {
                    label: this.props.resources.translate('increase-card-limit'),
                    backgroundColor: '#00FF95',
                    data: increaseCardLimit,
                }, {
                    label: this.props.resources.translate('other'),
                    backgroundColor: '#BDC3C7',
                    data: unknown,
                } ]
            },
        });


        this.setState({
            stats: {
                finished: gSuccess + gError,
                notFinished: gOther,
                total: gSuccess + gError + gOther,
                success: gSuccess,
                failed: gError,
            }
        });
    }


    render = (): ReactNode => {
        const icon = "fa-search";
        return (
            <div>
                <div className="col-md-8" style={{ width: "100%", height: "60vh", margin: "0px 0px 20px 0px" }}>
                    <div className="card" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{this.props.resources.translate('summary-validations')}</div>
                                <div className="subtitle">{this.props.resources.translate('between-dates', { days: this.state.numberDays, dateInit: this.state.selectedInitDate.toLocaleDateString(), dateFinish: this.state.selectedEndDate.toLocaleDateString() })}</div>
                            </div>
                        </div>
                        <div className="card-body row" style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                            <div className="col-md-6">
                                <canvas ref={this.summaryChartRef} />
                            </div>
                            <div className="col-md-6">
                                <canvas ref={this.productChartRef} />
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>

                <p></p>

                <div className="panel-tabs">
                    <div className="panel-heading">
                        <ul className="nav nav-tabs">
                            {this.props.haveOrPermission() && <li className={this.state.step === INSTANCES_STEP ? "active" : ""}><a onClick={() => this.setStep(INSTANCES_STEP)} href="#" className="nav-link" >{this.props.resources.translate('recent-validations')}</a></li>}
                        </ul>
                    </div>
                    <div className="panel-body">
                        {this.state.step === INSTANCES_STEP && <div className="card">
                            <div className="card-body">
                                <OnBoardingFlowFilter selectedSearch={this.state.selectedSearch}
                                    handleSearchSelected={this.handleSearchSelected}
                                    selectedInitDate={this.state.selectedInitDate}
                                    selectedEndDate={this.state.selectedEndDate}
                                    cellphoneFilter={this.state.cellphoneFilter}
                                    handleDateInitChange={this.handleDateInitChange}
                                    handleDateEndChange={this.handleDateEndChange}
                                    handleApplySelected={this.handleApplySelected}
                                    handleCellphoneFilter={this.handleCellphoneFilter}
                                    handleCancel={this.handleCancel} />

                                <div className="panel fresh-color panel-info">
                                    <div className="panel-heading">
                                        <div className="title">{this.props.resources.translate('curus-on-boarding-validation-select')}</div>
                                    </div>
                                    <TaskApplicationFlowList taskFlows={this.state.taskApplicationFlows}
                                        handleShowTaskApplicationFlow={this.handleShowTaskApplicationFlow}
                                    />
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default withAppPersonContext(OnBoardingPortalDashboardView);



