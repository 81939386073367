import { AppContextProps, withAppContext } from "@identity/context";
import { AttachmentRequirement } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router-dom";

export interface AttachReqListProps extends RouteComponentProps, AppContextProps {
    attachs: AttachmentRequirement[];
    tableOptions: any;
    handleSelected?: (selected: AttachmentRequirement) => void;
}
export interface AttachReqListState {
    selected: AttachmentRequirement | undefined;
}

class AttachReqList extends React.Component<AttachReqListProps, AttachReqListState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: AttachReqListProps) {
        super(props);
        this.state = {
            selected: undefined,
        };
        this.props.configureClass(this.obParser);
    }

    handleSelected = (selected: AttachmentRequirement): void => {
        this.setState({ selected });
        if (this.props.handleSelected)
            this.props.handleSelected(selected);
    }

    render = (): ReactNode =>
    (
        <BootstrapTable
            data={this.props.attachs}
            options={this.props.tableOptions}
            selectRow={this.props.handleSelected ? { mode: 'radio', onSelect: this.handleSelected } : { mode: 'none' }}>
            <TableHeaderColumn isKey={true}  dataAlign="center" dataField='id' dataSort hidden={true}>ID</TableHeaderColumn>
            <TableHeaderColumn dataField='name' dataAlign="center" dataSort>{this.props.resources.translate('name')}</TableHeaderColumn>
            <TableHeaderColumn dataField='required' dataAlign="center" dataSort dataFormat={this.obParser.formatBoolean}>{this.props.resources.translate('required')}</TableHeaderColumn>
            <TableHeaderColumn dataField='status' dataAlign="center" dataSort dataFormat={this.obParser.formatStatusAttach}>{this.props.resources.translate('status')}</TableHeaderColumn>
        </BootstrapTable>
    )
}

export default withAppContext(AttachReqList);