import { ApiResponseError } from "@curus/api";
import { AppContextProps, withAppContext } from "@identity/context";
import { DocumentAgreement } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router-dom";
import OnBoardingServices from "services/onboarding";

export interface AgreementDocumentListProps extends RouteComponentProps, AppContextProps {
    documents: DocumentAgreement[];
    tableOptions: any;
    handleSelectedDocument?: (documentSelected: DocumentAgreement) => void;
}
export interface AgreementDocumentListState {
    documentSelected: DocumentAgreement | undefined;
}

class AgreementDocumentList extends React.Component<AgreementDocumentListProps, AgreementDocumentListState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();
    protected obServices: OnBoardingServices = new OnBoardingServices();

    constructor(props: AgreementDocumentListProps) {
        super(props);
        this.state = {
            documentSelected: undefined,
        };
        console.log(this.props.documents);
        this.props.configureClass(this.obParser);
        this.props.configureClass(this.obServices);
    }

    handleSelectedDocument = (documentSelected: DocumentAgreement): void => {
        this.setState({ documentSelected });
        if (this.props.handleSelectedDocument)
            this.props.handleSelectedDocument(documentSelected);
    }



    render = (): ReactNode =>
    (
        <BootstrapTable
            data={this.props.documents}
            options={this.props.tableOptions}
            selectRow={this.props.handleSelectedDocument ? { mode: 'radio', onSelect: this.handleSelectedDocument } : { mode: 'none' }}>
            <TableHeaderColumn isKey={true} dataAlign="center" dataField='id' dataSort>#</TableHeaderColumn>
            <TableHeaderColumn dataField='name' dataAlign="center" dataSort>{this.props.resources.translate('name')}</TableHeaderColumn>
            <TableHeaderColumn dataAlign="center" dataSort>{this.props.resources.translate('status')}</TableHeaderColumn>
        </BootstrapTable>
    )
}

export default withAppContext(AgreementDocumentList);