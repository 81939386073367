import { ApiRoute, ApiMethod } from "@curus/api";
import { AGREEMENT_PACKAGE_ID_PARAM, AGREEMENT_DOCUMENT_ID_PARAM, AGREEMENT_ATTACHMENT_ID_PARAM, ONBOARDING_ID_PARAM, NOTIFICATION_TYPE_PARAM, INSTANCE_FLOW_PRINCIPAL_ID_PARAM, ONBOARDING_PORTAL_ID_PARAM } from "routing/param";
import { INSTANCE_FLOW_ID_PARAM } from "@identity/routing-on-boarding/param";
//Rutas React
export const CURUS_ON_BOARDING_JOINT_FLOW_ROUTE = '/onboarding/flow/joint/' + INSTANCE_FLOW_PRINCIPAL_ID_PARAM;

export const CURUS_ON_BOARDING_PORTAL_JOINT_FLOW_ROUTE = '/portal/flow/joint/' + INSTANCE_FLOW_PRINCIPAL_ID_PARAM;

export const CURUS_ON_BOARDING_PORTAL_ROUTE = '/portal';

export const CURUS_ON_BOARDING_PORTAL_FLOW_ROUTE = CURUS_ON_BOARDING_PORTAL_ROUTE + '/flow/' + INSTANCE_FLOW_ID_PARAM;

// Rutas API
const ON_BOARDING_API = '/api/v2/module/onboarding';

const ON_BOARDING_ADMIN_API = ON_BOARDING_API + '/admin';

const ON_BOARDING_AGREEMENTS_API = ON_BOARDING_ADMIN_API + '/agreement';

const ON_BOARDING_AGREEMENT_PACKAGE_API = ON_BOARDING_AGREEMENTS_API + '/package/' + AGREEMENT_PACKAGE_ID_PARAM;

const ON_BOARDING_AGREEMENT_AUDIT_API = ON_BOARDING_AGREEMENTS_API + '/packages/' + AGREEMENT_PACKAGE_ID_PARAM + '/audit';

const ON_BOARDING_AGREEMENT_DOCUMENT_API = ON_BOARDING_AGREEMENT_PACKAGE_API + '/documents' + '/' + AGREEMENT_DOCUMENT_ID_PARAM;

const ON_BOARDING_AGREEMENT_ATTACHMENT_API = ON_BOARDING_AGREEMENT_PACKAGE_API + '/attachment' + '/' + AGREEMENT_ATTACHMENT_ID_PARAM;

const ON_BOARDING_NOTIFICATIONS_API = ON_BOARDING_ADMIN_API + '/instances' + '/' + ONBOARDING_ID_PARAM + '/notification' + '/' + NOTIFICATION_TYPE_PARAM;

const ON_BOARDING_INSTANCE_JOINT_API = ON_BOARDING_ADMIN_API + '/instances/principal';

const ON_BOARDING_PORTAL_API = '/api/v2/module/portal';

const ON_BOARDING_PORTAL_ADMIN_API = ON_BOARDING_PORTAL_API + '/admin';

const ON_BOARDING_PORTAL_AGREEMENTS_API = ON_BOARDING_PORTAL_ADMIN_API + '/agreement';

const ON_BOARDING_PORTAL_AGREEMENT_PACKAGE_API = ON_BOARDING_PORTAL_AGREEMENTS_API + '/package/' + AGREEMENT_PACKAGE_ID_PARAM;

const ON_BOARDING_PORTAL_AGREEMENT_AUDIT_API = ON_BOARDING_PORTAL_AGREEMENTS_API + '/packages/' + AGREEMENT_PACKAGE_ID_PARAM + '/audit';

const ON_BOARDING_PORTAL_AGREEMENT_DOCUMENT_API = ON_BOARDING_PORTAL_AGREEMENT_PACKAGE_API + '/documents' + '/' + AGREEMENT_DOCUMENT_ID_PARAM;

const ON_BOARDING_PORTAL_AGREEMENT_ATTACHMENT_API = ON_BOARDING_PORTAL_AGREEMENT_PACKAGE_API + '/attachment' + '/' + AGREEMENT_ATTACHMENT_ID_PARAM;

const ON_BOARDING_PORTAL_INSTANCES_API = ON_BOARDING_PORTAL_ADMIN_API + '/instances';

const ON_BOARDING_PORTAL_INSTANCE_API = ON_BOARDING_PORTAL_INSTANCES_API + '/' + INSTANCE_FLOW_ID_PARAM;

const ON_BOARDING_PORTAL_INSTANCE_JOINT_API = ON_BOARDING_PORTAL_ADMIN_API + '/instances/principal';

const ON_BOARDING_PORTAL_NOTIFICATIONS_API = ON_BOARDING_PORTAL_INSTANCES_API + '/' + ONBOARDING_PORTAL_ID_PARAM + '/notification' + '/' + NOTIFICATION_TYPE_PARAM;


export const ON_BOARDING_AGREEMENT_PACKAGE_API_ROUTE: ApiRoute = { method: ApiMethod.GET, url: ON_BOARDING_AGREEMENT_PACKAGE_API };

export const ON_BOARDING_AGREEMENT_DOCUMENT_API_ROUTE: ApiRoute = { method: ApiMethod.GET, url: ON_BOARDING_AGREEMENT_DOCUMENT_API };

export const ON_BOARDING_AGREEMENT_ATTACHMENT_API_ROUTE: ApiRoute = { method: ApiMethod.GET, url: ON_BOARDING_AGREEMENT_ATTACHMENT_API };

export const ON_BOARDING_AGREEMENT_AUDIT_API_ROUTE: ApiRoute = { method: ApiMethod.GET, url: ON_BOARDING_AGREEMENT_AUDIT_API };

export const ON_BOARDING_NOTIFICATION_API_ROUTE: ApiRoute = { method: ApiMethod.GET, url: ON_BOARDING_NOTIFICATIONS_API };

export const ON_BOARDING_INSTANCES_JOINT_API_ROUTE: ApiRoute = { method: ApiMethod.POST, url: ON_BOARDING_INSTANCE_JOINT_API };

export const ON_BOARDING_PORTAL_INSTANCES_API_ROUTE: ApiRoute = { method: ApiMethod.POST, url: ON_BOARDING_PORTAL_INSTANCES_API};

export const ON_BOARDING_PORTAL_INSTANCE_API_ROUTE: ApiRoute = { method: ApiMethod.GET, url: ON_BOARDING_PORTAL_INSTANCE_API};

export const ON_BOARDING_PORTAL_JOINT_API_ROUTE: ApiRoute = { method: ApiMethod.POST, url: ON_BOARDING_PORTAL_INSTANCE_JOINT_API};

export const ON_BOARDING_PORTAL_NOTIFICATION_API_ROUTE: ApiRoute = { method: ApiMethod.GET, url: ON_BOARDING_PORTAL_NOTIFICATIONS_API };

export const ON_BOARDING_PORTAL_AGREEMENT_PACKAGE_API_ROUTE: ApiRoute = { method: ApiMethod.GET, url: ON_BOARDING_PORTAL_AGREEMENT_PACKAGE_API};

export const ON_BOARDING_PORTAL_AGREEMENT_DOCUMENT_API_ROUTE: ApiRoute = { method: ApiMethod.GET, url: ON_BOARDING_PORTAL_AGREEMENT_DOCUMENT_API };

export const ON_BOARDING_PORTAL_AGREEMENT_ATTACHMENT_API_ROUTE: ApiRoute = { method: ApiMethod.GET, url: ON_BOARDING_PORTAL_AGREEMENT_ATTACHMENT_API };

export const ON_BOARDING_PORTAL_AGREEMENT_AUDIT_API_ROUTE: ApiRoute = { method: ApiMethod.GET, url: ON_BOARDING_PORTAL_AGREEMENT_AUDIT_API };