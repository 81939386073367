import React from "react";
import { Route, Switch } from "react-router";
import SideMenu from '@curus/side-menu';
import { USER_SIDE_MENU_ITEMS } from "@identity/navigation-person/user";
import { CURUS_ON_BOARDING_DOCUMENT_VALIDATION_ROUTE, CURUS_ON_BOARDING_FLOW_ROUTE, CURUS_ON_BOARDING_ROUTE } from "@identity/routing-on-boarding";
import { ON_BOARDING_ADMIN_PERMISSION } from "@identity/routing-on-boarding/permission";
import { USER_MAIN_ROUTE, USER_SEARCH_ROUTE } from "@identity/routing-person";
import { USER_GET_PERM } from "@identity/routing-person/permission";
import { CURUS_ON_BOARDING_PORTAL_FLOW_ROUTE, CURUS_ON_BOARDING_PORTAL_ROUTE } from "routing";

export default <Switch>
    <Route path={USER_MAIN_ROUTE} render={() => <SideMenu items={USER_SIDE_MENU_ITEMS} />} />
    <Route render={() => <SideMenu items={[
        { id: "UserSearch", text: 'user-find', icon: "fa-users", path: USER_SEARCH_ROUTE, select: [], permissions: [USER_GET_PERM] },
        { id: "OnBoardingMenu", text: 'curus-on-boarding', icon: "fa-address-card", path: CURUS_ON_BOARDING_ROUTE, select: [CURUS_ON_BOARDING_FLOW_ROUTE, CURUS_ON_BOARDING_DOCUMENT_VALIDATION_ROUTE], permissions: [ON_BOARDING_ADMIN_PERMISSION] },
        { id: "TaskMenu", text: 'curus-task', icon: "fa-address-card", path: CURUS_ON_BOARDING_PORTAL_ROUTE, select: [CURUS_ON_BOARDING_PORTAL_FLOW_ROUTE], permissions: [ON_BOARDING_ADMIN_PERMISSION] }
    ]} />} />
</Switch>;
