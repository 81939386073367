import { AppContextProps, withAppContext } from "@identity/context";
import { Signer } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router-dom";

export interface RolSignerListProps extends RouteComponentProps, AppContextProps {
    signers: Signer[];
    tableOptions: any;
    handleSelected?: (selected: Signer) => void;
}
export interface RolSignerListState {
    selected: Signer | undefined;
}

class RolSignerList extends React.Component<RolSignerListProps, RolSignerListState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: RolSignerListProps) {
        super(props);
        this.state = {
            selected: undefined,
        };
        this.props.configureClass(this.obParser);
    }

    handleSelected = (selected: Signer): void => {
        this.setState({ selected });
        if (this.props.handleSelected)
            this.props.handleSelected(selected);
    }

    render = (): ReactNode =>
    (
        <BootstrapTable
            data={this.props.signers}
            options={this.props.tableOptions}
            selectRow={this.props.handleSelected ? { mode: 'radio', onSelect: this.handleSelected } : { mode: 'none' }}>
            <TableHeaderColumn isKey={true} dataAlign="center" dataField='id' dataSort hidden={true}>ID</TableHeaderColumn>
            <TableHeaderColumn dataField='email' dataAlign="center" dataSort>{this.props.resources.translate('email')}</TableHeaderColumn>
            <TableHeaderColumn dataField='name' dataAlign="center" dataSort>{this.props.resources.translate('name')}</TableHeaderColumn>
            <TableHeaderColumn dataField='updated' dataAlign="center" dataSort>{this.props.resources.translate('date-last-event')}</TableHeaderColumn>
            <TableHeaderColumn dataField='signature' dataAlign="center" dataSort >{this.props.resources.translate('status')} Firma PENDIENTE</TableHeaderColumn>
        </BootstrapTable>
    )
}

export default withAppContext(RolSignerList);