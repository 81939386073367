import { AppContextProps, withAppContext } from "@identity/context";
import { TaskApplicationFlow } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router-dom";
import ReactExport from "react-export-excel";
import { format } from "date-fns-tz";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export interface TaskApplicationFlowListProps extends RouteComponentProps, AppContextProps {
    taskFlows: TaskApplicationFlow[];
    handleSelected?: (selected: TaskApplicationFlow) => void;
    handleShowTaskApplicationFlow: (id: string | undefined, type: string | undefined) => void;
}
export interface TaskApplicationFlowListState {
    selected: TaskApplicationFlow | undefined;
    tableOptions: any;
}

class TaskApplicationFlowList extends React.Component<TaskApplicationFlowListProps, TaskApplicationFlowListState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: TaskApplicationFlowListProps) {
        super(props);
        this.state = {
            selected: undefined,
            tableOptions: {
                sortName: 'createDate',
                sortOrder: 'desc',
            }
        };
        this.props.configureClass(this.obParser);
    }

    handleSelected = (selected: TaskApplicationFlow): void => {
        this.setState({ selected });
        if (this.props.handleSelected) {
            this.props.handleSelected(selected);
        }
    }

    formatInstanceId = (id: string): string | JSX.Element => {
        if (this.props.handleSelected) return id;
        return <a href="#" onClick={(e: any) => {
            e.preventDefault(); 
            let data=this.props.taskFlows       
            data.forEach(element => {
                if(element.id==id){
                    this.props.handleShowTaskApplicationFlow(id, element.type);
                }
            });   
            
        }}>{id}</a>;
    }

    render = (): ReactNode => (
    <div>
        <BootstrapTable
            data={this.props.taskFlows}
            selectRow={{
                mode: this.props.handleSelected ? 'radio' : 'none',
                onSelect: this.handleSelected,
            }}
            options={this.state.tableOptions}
            bordered pagination>
            <TableHeaderColumn dataField='id' isKey width="160" dataFormat={this.formatInstanceId}>{this.props.resources.translate('id-list')}</TableHeaderColumn>
            <TableHeaderColumn dataField='actionType' dataAlign="center" dataSort dataFormat={this.props.resources.translateEnum}>{this.props.resources.translate('action')}</TableHeaderColumn>
            <TableHeaderColumn dataField='jointAccountType' dataAlign="center" dataSort>{this.props.resources.translate('joint-account')}</TableHeaderColumn>    
            <TableHeaderColumn dataField='cellphone' dataAlign="center" dataSort>{this.props.resources.translate('phone-mobile')}</TableHeaderColumn>
            <TableHeaderColumn dataField='createDate' dataAlign="center" dataSort>{this.props.resources.translate('date-creation')}</TableHeaderColumn>
            <TableHeaderColumn dataField='taskStatus' dataAlign="center" dataSort dataFormat={this.obParser.formatFlowStatus}>{this.props.resources.translate('status')}</TableHeaderColumn>
        </BootstrapTable>

        <ExcelFile element={<button className="btn btn-info">Download Admin Portal Data</button>}>
            <ExcelSheet data={this.props.taskFlows} name="Onboarding Application">
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label="Action Type" value="actionType"/>
            <ExcelColumn label="Joint Account Type" value="jointAccountType"/>
            <ExcelColumn label="Cellphone" value="cellphone"/>
            <ExcelColumn label="Task Status" value="taskStatus"/>
            <ExcelColumn label="Date" value={(col) => format(col.createDate, "dd/MM/yyyy HH:mm:ss")}/>
            </ExcelSheet>
        </ExcelFile>
    </div>
    )
}

export default withAppContext(TaskApplicationFlowList);