import { AppContextProps, withAppContext } from "@identity/context";
import { DocumentField, IdentityValidation, Match } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";
import BiometricInfo from "components/on-boarding/flow/validation/identity/biometric/info";
import DocumentImage from "components/on-boarding/flow/validation/identity/document"
import Collapsible from "react-collapsible";
import DocumentMatchInfoPopUp from "components/on-boarding/flow/validation/identity/document/field/info"

export interface ValidationIdentityProps extends RouteComponentProps, AppContextProps {
    validation: IdentityValidation;
    tableOptions: any;
    handleSelected: (match: Match) => void;
}
export interface ValidationIdentityState {
    selected: IdentityValidation | undefined;
    dataStep: number;
    selectedMatch: Match | undefined;
    showMatchInfo: boolean;
}

const FIELDS: number = 0;
const MATCHES: number = 1;
const SCORES: number = 2;

class ValidationIdentity extends React.Component<ValidationIdentityProps, ValidationIdentityState> {


    setDataStep = (dataStep: number): void => {
        this.setState({ dataStep });
    }

    protected obParser: OnBoardingParser = new OnBoardingParser();
    biometricImagesRefs: string[] = [
        "selfie",
        "selfie2",
        "passportFace"
    ];


    constructor(props: ValidationIdentityProps) {
        super(props);
        this.state = {
            selected: undefined,
            dataStep: FIELDS,
            selectedMatch: undefined,
            showMatchInfo: false
        };
        this.props.configureClass(this.obParser);
    }
    hideMatchInfo = (): void => {
        this.setState({ showMatchInfo: false });
    }
    handleSelectedMatch = (selectedMatch: Match): void => {
        this.setState({ selectedMatch, showMatchInfo: true });
    }

    handleSelected = (selectedMatch: Match): void => {
        this.setState({ selectedMatch });
        this.handleSelectedMatch(selectedMatch);
    }



    render = (): ReactNode => {
        let rows: JSX.Element[] | undefined = undefined;
        let documentFields: JSX.Element[] | undefined = undefined;
        let scoreRows: JSX.Element[] | undefined = undefined;
        if (this.props.validation.matches != null) {
            rows = this.props.validation.matches.map((f: Match, i: number) =>
                <tr
                    className={"tr-clickable"}
                    onClick={() => this.handleSelected(f)}
                    key={i}>
                    <td>{this.props.resources.translateEnum(f.matchedData.toUpperCase())}</td>
                    <td>{f.dataExtracted}</td>
                    <td>{f.dataSubmitted}</td>
                    <td>{this.obParser.formatResult(f.result)}</td>
                    <td className="word-wrap">{f.comment}</td>
                </tr>
            );
        }
        if (this.props.validation.documentfields != null) {
            documentFields = this.props.validation.documentfields.map((f: DocumentField, i: number) =>
                <tr
                    className={"tr-clickable"}
                    key={i}>
                    <td>{this.props.resources.translateEnum(f.name.toUpperCase())}</td>
                    <td>{f.text}</td>
                </tr>
            );
        }
        if (this.props.validation.documentScores != null) {
            scoreRows = Object.entries(this.props.validation.documentScores).map(([key, value]) =>
                <tr
                    className={"tr-clickable"}
                    key={key}>
                    <td>{key}</td>
                    <td>{value}</td>
                </tr>
            );
        }
        const imagesBiometric: string[] = [];
        if (this.props.validation.images != null) {
            this.biometricImagesRefs.forEach((r: string) => {
                const src: string = `data:image/JPG;base64,${this.props.validation.images[r]}`;
                if (src) imagesBiometric.push(src);
            });
        }
        const colClass: string = this.props.validation.dates ? 'col-md-6' : 'col-md-12';
        return (
            <div>
                <DocumentMatchInfoPopUp open={this.state.showMatchInfo}
                    documentMatch={this.state.selectedMatch}
                    close={this.hideMatchInfo} />
                <div className="row">
                    <div className={colClass}>
                        <div className="card margin-card-top">
                            <div className="card-header">
                                <div className="card-title">
                                    <div className="title">{this.props.resources.translate('document-authentication')}</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-custom">
                                    <tbody className="data">
                                        {this.props.validation.status && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('result')}:</th>
                                            <td colSpan={3}>{this.obParser.formatResult(this.props.validation.status.result)}</td>
                                        </tr>}
                                        {this.props.validation.status.comment && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('comment')}:</th>
                                            <td colSpan={3}>{this.props.validation.status.comment}</td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {this.props.validation.dates && <div className="col-md-6">
                        <div className="card margin-card-top">

                            <div className="card-header">
                                <div className="card-title">
                                    <div className="title">{this.props.resources.translate('validation-dates')}</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-custom">
                                    <tbody className="data">
                                        {this.props.validation.dates.created && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('date-creation')}:</th>
                                            <td colSpan={3}>{this.props.validation.dates.created.toString()}</td>
                                        </tr>}
                                        {this.props.validation.dates.expiry_timestamp && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('date-expiration')}:</th>
                                            <td colSpan={3}>{this.props.validation.dates.expiry_timestamp.toString()}</td>
                                        </tr>}
                                        {this.props.validation.dates.last_event && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('date-last-event')}:</th>
                                            <td colSpan={3}>{this.props.validation.dates.last_event.toString()}</td>
                                        </tr>}
                                        {this.props.validation.dates.completed && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('date-completed')}:</th>
                                            <td colSpan={3}>{this.props.validation.dates.completed.toString()}</td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                </div>
                {this.props.validation.images &&
                    <div className="card margin-card-top">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{this.props.resources.translate('passport-data')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="card container-document margin-card-top">

                                <div className="panel-tabs">
                                    <div className="panel-body">
                                        <DocumentImage image={`data:image/JPG;base64,${this.props.validation.images["passport"]}`} />
                                    </div>
                                </div>
                                <div className="panel-tabs">
                                    <div className="panel-heading">
                                        <ul className="nav nav-tabs">
                                            <li className={this.state.dataStep === FIELDS ? "active" : ""}><a onClick={() => this.setDataStep(FIELDS)} className="nav-link" >{this.props.resources.translate('document-fields')}</a></li>
                                            <li className={this.state.dataStep === MATCHES ? "active" : ""}><a onClick={() => this.setDataStep(MATCHES)} className="nav-link" >{this.props.resources.translate('matchs')}</a></li>
                                            <li className={this.state.dataStep === SCORES ? "active" : ""}><a onClick={() => this.setDataStep(SCORES)} className="nav-link" >{this.props.resources.translate('scores')}</a></li>
                                        </ul>
                                    </div>
                                    <div className="panel-body scrollable">

                                        {this.props.validation.documentfields && this.state.dataStep === FIELDS &&
                                            <div className="table-responsive">
                                                <table className="table table-bordered ellipsis fixed">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center col-md-2">{this.props.resources.translate('field')}</th>
                                                            <th className="text-center col-md-2">{this.props.resources.translate('value')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {documentFields}
                                                    </tbody>
                                                </table>
                                            </div>}
                                        {this.props.validation.matches && this.state.dataStep === MATCHES &&
                                            <div className="table-responsive">
                                                <table className="table table-bordered ellipsis fixed">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center col-md-2">{this.props.resources.translate('Match')}</th>
                                                            <th className="text-center col-md-2">{this.props.resources.translate('data-extracted')}</th>
                                                            <th className="text-center col-md-2">{this.props.resources.translate('data-submitted')}</th>
                                                            <th className="text-center col-md-2">{this.props.resources.translate('result')}</th>
                                                            <th className="text-center col-md-4">{this.props.resources.translate('comment')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {rows}
                                                    </tbody>
                                                </table>
                                            </div>}

                                        {this.props.validation.documentScores && this.state.dataStep === SCORES &&
                                            <div className="table-responsive">
                                                <table className="table table-bordered ellipsis fixed">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center col-md-2">{this.props.resources.translate('validation')}</th>
                                                            <th className="text-center col-md-2">{this.props.resources.translate('score')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {scoreRows}
                                                    </tbody>
                                                </table>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                {this.props.validation.biometryScores && <div>
                    <BiometricInfo biometryScores={this.props.validation.biometryScores}
                        imageSources={imagesBiometric}
                        tableOptions={this.props.tableOptions}
                        matches={this.props.validation.matches} />
                </div>}
            </div>
        )
    }
}

export default withAppContext(ValidationIdentity);