import { Formatter } from "@curus/utils";
import { IdentityResources } from "@identity/classes";
import { IdentityConfig } from "@identity/classes";
import { AppContextProps, withAppContext } from "@identity/context";
import { Match } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router-dom";

export interface BiometricInfoProps extends RouteComponentProps, AppContextProps {
    biometryScores: { [key: string]: number };
    tableOptions: any;
    imageSources?: string[];
    matches: Match[];
}
export interface BiometricInfoState { }

class BiometricInfo extends React.Component<BiometricInfoProps, BiometricInfoState> {
    protected formatter: Formatter<IdentityConfig, IdentityResources> = new Formatter();
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: BiometricInfoProps) {
        super(props);
        this.props.configureClass(this.formatter, this.obParser);
    }

    getResultScore = (matches: Match[], score: number): string => {
        var res: string = "";
        matches.map((match) => {
            var n = Number(match.dataExtracted);
            if (n) {
                if (n === score) {
                    res = match.result;
                }
            }
        });
        return res;
    }

    render = (): ReactNode => {
        const rows: JSX.Element[] = Object.entries(this.props.biometryScores).map(([key, value]) =>
            <tr
                className={"tr-clickable text-center"}
                key={key}>
                <td>{this.props.resources.translateEnum(key.toUpperCase())}</td>
                <td>{value}</td>
                <td>{this.obParser.formatResult(this.getResultScore(this.props.matches, value))}</td>
            </tr>
        );

        return (

            <div >
                <div className="card margin-card-top">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{this.props.resources.translate('biometric-validation')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="card col-md-6" >
                            <div className="card-header">
                                <div className="card-title">
                                    <div className="title">{this.props.resources.translate('biometric-scores')}</div>
                                </div>
                            </div>
                            {this.props.biometryScores &&
                                <div className="card-body">
                                    <table className="table table-custom">
                                        <thead>
                                            <tr>
                                                <th className="text-center col-md-2">{this.props.resources.translate('validation')}</th>
                                                <th className="text-center col-md-2">{this.props.resources.translate('score')}</th>
                                                <th className="text-center col-md-2">{this.props.resources.translate('result')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows}
                                        </tbody>
                                    </table>
                                </div>}
                        </div>
                        <div className="card col-md-6" style={{ height: '100%' }}>
                            <div className="card-header">
                                <div className="card-title">
                                    <div className="title">{this.props.resources.translate('images')}</div>
                                </div>
                            </div>
                            <div className="card-body card-body-images">
                                {this.props.imageSources && this.props.imageSources.map((s: string, i: number) => <img key={i} src={s} />)}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withAppContext(BiometricInfo);