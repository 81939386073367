import { AppContextProps, withAppContext } from "@identity/context";
import { OtpValidation } from "interfaces";
import { RouteComponentProps } from "react-router-dom";
import ValidationOtp from "../../validation/otp/info"
import React, { ReactNode } from "react";
import ValidationOtpList from "components/on-boarding/flow/validation/otp/list"

export interface StepOtpValidationsProps extends RouteComponentProps, AppContextProps {
    validations: OtpValidation[];
    tableOptions: any;
}
export interface StepOtpValidationsState {
    selected: OtpValidation | undefined;
}

export class StepOtpValidations<P extends StepOtpValidationsProps, S extends StepOtpValidationsState> extends React.Component<P, S> {

    constructor(props: P) {
        super(props);
        this.state = {
            selected: undefined,
        } as S;

    }

    handleSelectedValidation = (selected: OtpValidation): void => {
        this.setState({ selected });
    }



    render = (): ReactNode => {
        return (
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{this.props.resources.translate('validations')}</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="panel fresh-color panel-info">
                            <div className="panel-heading">
                                <div className="title">{this.props.resources.translate('select-validation')}</div>
                            </div>
                            <ValidationOtpList validations={this.props.validations}
                                tableOptions={this.props.tableOptions}
                                handleSelected={this.handleSelectedValidation} />
                        </div>
                        {this.state.selected && <div className="panel fresh-color panel-info">
                            <div className="panel-heading">
                                <div className="title">{this.props.resources.translate('validation')}</div>
                            </div>
                            <div className="panel-body">
                                <ValidationOtp validation={this.state.selected}
                                    tableOptions={this.props.tableOptions} />
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

        )
    }
}

export default withAppContext(StepOtpValidations);