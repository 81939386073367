import { Formatter } from "@curus/utils";
import { IdentityConfig, IdentityResources } from "@identity/classes";
import { AppContextProps, withAppContext } from "@identity/context";
import { AgreementAuditEvent, AttachmentRequirement, DocumentAgreement, Notifications, OnboardingAgreementPackage, PackageRole, Signer, StepFlow } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";
import AgreementDocumentList from "components/on-boarding/flow/step/agreement/document/list"
import AgreementPDF from "components/on-boarding/flow/step/agreement/document/info"
import RolSignerList from "components/on-boarding/flow/step/agreement/rol/signer/list";
import SignerInfo from "components/on-boarding/flow/step/agreement/rol/signer/info";
import AttachReqList from "components/on-boarding/flow/step/agreement/rol/attachmentRequirements/list"
import AttachReqInfo from "components/on-boarding/flow/step/agreement/rol/attachmentRequirements/info"
import OnBoardingServices from "services/onboarding";
import { RouteAgreementPackageParams } from "routing/param";
import { ApiResponseError } from "@curus/api";
import AgreementEventList from "components/on-boarding/flow/step/agreement/event";
import NotificationsAgreementList from "components/on-boarding/flow/validation/identity/notifications/agreement";


export interface StepAgreementInfoProps extends RouteComponentProps, AppContextProps {
    step: StepFlow;
    notifications: Notifications[];
    tableOptions: any;
}
export interface StepAgreementInfoState {
    tableOptions: any;
    agreementDetail: OnboardingAgreementPackage | undefined;
    selected: PackageRole | undefined;
    documentSelected: DocumentAgreement | undefined;
    selectedSigner: Signer | undefined;
    selectedAttach: AttachmentRequirement | undefined;
    params: RouteAgreementPackageParams;
    events: AgreementAuditEvent[] | undefined;
}


class StepAgreementInfo extends React.Component<StepAgreementInfoProps, StepAgreementInfoState> {
    protected formatter: Formatter<IdentityConfig, IdentityResources> = new Formatter();
    protected obParser: OnBoardingParser = new OnBoardingParser();
    protected obServices: OnBoardingServices = new OnBoardingServices();

    _isMounted = false;

    componentDidMount = (): void => {
        this.fetchEvents();
        this._isMounted = true;
    }



    constructor(props: StepAgreementInfoProps) {
        super(props);
        this.state = {
            agreementDetail: this.props.step.details[0],
            tableOptions: {
                noDataText: this.props.resources.translate('table-empty'),
            },
            selected: undefined,
            selectedSigner: undefined,
            selectedAttach: undefined,
            documentSelected: undefined,
            params: {
                packageId: this.props.step.details[0].id
            },
            events: undefined
        };
        this.props.configureClass(this.formatter, this.obParser);
        this.props.configureClass(this.obServices);
    }

    fetchEvents = (): void => {
        this.props.showLoading(true);
        this.obServices
            .agreementAudit(this.state.params)
            .then((events: any) => {
                this.setState({ events });
                this.props.showLoading(false);

            })
            .catch((response: ApiResponseError) => {
                this.props.showAlert(response.message);
                this.props.showLoading(false);
            });
    }

    handleSelectedRole = (selected: PackageRole): void => {
        this.setState({ selected });
    }

    handleSelectedDocument = (documentSelected: DocumentAgreement): void => {
        this.setState({ documentSelected });
    }

    handleSelectedSigner = (selectedSigner: Signer): void => {
        this.setState({ selectedSigner });
    }

    handleSelectedAttach = (selectedAttach: AttachmentRequirement): void => {
        this.setState({ selectedAttach });
    }



    render = (): ReactNode => (
        <div>
            <div className="card">
                <div className="col-md-8">
                    <div className="card-header">
                        <div className="card-title">
                            <div className="title">{this.props.resources.translate('agreement-info')}</div>
                        </div>
                    </div>
                    {this.state.agreementDetail && <div className="card-body">
                        <table className="table table-custom">
                            <tbody className="data">
                                {this.state.agreementDetail?.name && <tr>
                                    <th className="col-lg-3">{this.props.resources.translate('name')}:</th>
                                    <td colSpan={3}>{this.state.agreementDetail?.name}</td>
                                </tr>}
                                {this.state.agreementDetail?.id && <tr>
                                    <th className="col-lg-3">ID:</th>
                                    <td colSpan={3}>{this.state.agreementDetail?.id}</td>
                                </tr>}
                                {this.state.agreementDetail?.created && <tr>
                                    <th className="col-lg-3">{this.props.resources.translate('date-creation')}:</th>
                                    <td colSpan={3}>{this.state.agreementDetail?.created}</td>
                                </tr>}
                                {this.state.agreementDetail?.updated && <tr>
                                    <th className="col-lg-3">{this.props.resources.translate('date-last-event')}:</th>
                                    <td colSpan={3}>{this.state.agreementDetail?.updated}</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{this.props.resources.translate('documents')}</div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="panel fresh-color panel-info">
                        <div className="panel-heading">
                            <div className="title">{this.props.resources.translate('select-document')}</div>
                        </div>
                        <AgreementDocumentList documents={this.state.agreementDetail?.documents}
                            tableOptions={this.props.tableOptions}
                            handleSelectedDocument={this.handleSelectedDocument} />
                    </div>
                    {this.state.documentSelected && <div className="panel fresh-color panel-info">
                        <div className="panel-heading">
                            <div className="title">{this.props.resources.translate('select-document')}</div>
                        </div>
                        {<div className="panel-body">
                            <AgreementPDF document={this.state.documentSelected}
                                packageId={this.state.agreementDetail?.id}
                                tableOptions={this.props.tableOptions} />
                        </div>}
                    </div>}
                </div>
            </div>
            {/*  <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{this.props.resources.translate('roles')}</div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="panel fresh-color panel-info">
                        <div className="panel-heading">
                            <div className="title">{this.props.resources.translate('select-role')}</div>
                        </div>
                        <AgreementRolList roles={this.state.agreementDetail?.roles}
                            tableOptions={this.props.tableOptions}
                            handleSelected={this.handleSelectedRole} />
                    </div>
                    {this.state.selected && <div className="panel fresh-color panel-info">
                        <div className="panel-heading">
                            <div className="title">{this.props.resources.translate('select-role')}</div>
                        </div>
                        {<div className="panel-body">
                            <RoleInfo packageRole={this.state.selected}
                                tableOptions={this.props.tableOptions} />
                        </div>}
                    </div>}
                </div>
            </div> */}
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{this.props.resources.translate('recipients')}</div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="panel fresh-color panel-info">
                        <div className="panel-heading">
                            <div className="title">{this.props.resources.translate('select-signer')}</div>
                        </div>
                        <RolSignerList signers={this.obParser.parseAllSigners(this.state.agreementDetail?.roles)}
                            tableOptions={this.props.tableOptions}
                            handleSelected={this.handleSelectedSigner} />
                    </div>
                    {this.state.selectedSigner && <div className="panel fresh-color panel-info">
                        <div className="panel-heading">
                            <div className="title">{this.props.resources.translateEnum('signers')}</div>
                        </div>
                        {<div className="panel-body">
                            <SignerInfo signer={this.state.selectedSigner}
                                tableOptions={this.props.tableOptions} />
                        </div>}
                    </div>}
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{this.props.resources.translate('recipient-uploads')}</div>
                    </div>
                </div>

                <div className="card-body">
                    <div className="panel fresh-color panel-info">
                        <div className="panel-heading">
                            <div className="title">{this.props.resources.translate('select-attach')}</div>
                        </div>
                        <AttachReqList attachs={this.obParser.parseAllAttach(this.state.agreementDetail?.roles)}
                            tableOptions={this.props.tableOptions}
                            handleSelected={this.handleSelectedAttach} />
                    </div>
                    {this.state.selectedAttach && <div className="panel fresh-color panel-info">
                        <div className="panel-heading">
                            <div className="title">{this.props.resources.translate('document-attach')}</div>
                        </div>
                        {<div className="panel-body">
                            <AttachReqInfo attachReq={this.state.selectedAttach}
                                tableOptions={this.props.tableOptions}
                                packageId={this.state.agreementDetail?.id} />
                        </div>}
                    </div>}
                </div>
            </div>

            {this.state.events && <div className="card">
                <div className="card-body">
                    <div className="panel fresh-color panel-info">
                        <div className="panel-heading">
                            <div className="title">{this.props.resources.translate('curus-on-boarding-validation-select')}</div>
                        </div>
                        <AgreementEventList events={this.state.events}
                            tableOptions={this.state.tableOptions} />
                    </div>
                </div>
            </div>}
            <NotificationsAgreementList notifications = {this.props.notifications}
                tableOptions={this.state.tableOptions}
                 />
        </div>



    )
}

export default withAppContext(StepAgreementInfo);