import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { DeviceType as AuthorizationDeviceType } from "@authorization/interfaces";
import CardsMenu from '@curus/cards-menu';
import Localization, { ResourceKey, LANGUAGE_ES, LANGUAGE_EN } from '@curus/localization';
import { LoginStepType, LoginStepStatus } from '@curus/login';
import PermissionRoute from "@curus/permission-route";
import { MAIN_ROUTE, NOT_FOUND_ROUTE } from '@curus/routing';
import IdentityApp from "@identity/app";
import { LANGUAGE_BUNDLE_ES, LANGUAGE_BUNDLE_EN, CURUS_CHANNEL_NAMES } from "@identity/constants";
import { DEVICE_ANY, DEVICE_GRID_CARD, DEVICE_NONE, DEVICE_OTP, DEVICE_PASSWORD, DEVICE_SOFT_TOKEN } from '@identity/constants-device';
import UserContainer from "@identity/container-person/user";
import { IdentityConfig, IdentityResources } from '@identity/interfaces';
import CurusOnBoardingNavigation, { CURUS_ON_BOARDING_NAVIGATION_ENTRY_POINT, CURUS_ON_BOARDING_PORTAL_NAVIGATION_ENTRY_POINT } from "navigation/onboarding";
import UserNavigation, { USER_NAVIGATION_ENTRY_POINT } from "@identity/navigation-person/user";
import { CURUS_ON_BOARDING_ROUTE, CURUS_ON_BOARDING_FLOW_ROUTE } from '@identity/routing-on-boarding';
import { ON_BOARDING_ADMIN_INSTANCE_GET_PERMISSION } from '@identity/routing-on-boarding/permission';
import { USER_SEARCH_ROUTE } from '@identity/routing-person';
import { LoginStepUsernamePassword } from '@identity/views-session';
import ClarienResources from "resources";
import NavBar from "views/nav-bar";
import OnBoardingFlowInfo from "views/on-boarding/flow";
import TaskFlowInfo from "views/portal/flow";
import SideMenu from "views/side-menu";
import { CURUS_ON_BOARDING_PORTAL_FLOW_ROUTE, CURUS_ON_BOARDING_PORTAL_ROUTE } from "routing";

// Configuración localización
const localeEs: ResourceKey = require('locales/es.json');
const localeEn: ResourceKey = require('locales/en.json');
const ENUM_MAP_BUNDLE: ResourceKey = require('locales/enum.json');

Localization.addResources(LANGUAGE_ES, LANGUAGE_BUNDLE_ES);
Localization.addResources(LANGUAGE_ES, localeEs);
Localization.addResources(LANGUAGE_EN, LANGUAGE_BUNDLE_EN);
Localization.addResources(LANGUAGE_EN, localeEn);
Localization.addEnumMap(ENUM_MAP_BUNDLE);

// Configuración App
const config: IdentityConfig = require('config.json');
if (process.env.REACT_APP_API_URL) {
    config.api.url = process.env.REACT_APP_API_URL;
}
if (process.env.REACT_APP_AUTH_URL) {
    config.authorization.url = process.env.REACT_APP_AUTH_URL;
}

// Recursos App
const appIcon: any = require('assets/images/icon.png').default;
const appLogo: any = require('assets/images/logo.png').default;

const resources: IdentityResources = {
    appIcon,
    appLogo,
    dicts: {
        routes: {},
        personTypes: {
            "CLIENTE": Localization.translate('client'),
            "IDENTITY": Localization.translate('identity'),
        },
        channelNames: {
            ...CURUS_CHANNEL_NAMES,
            "HOB": "Home Banking",
        },
        showRemainingAuthsFor: {
            client: [DEVICE_GRID_CARD, DEVICE_SOFT_TOKEN, DEVICE_OTP],
            user: [DEVICE_PASSWORD]
        },
    },
    lists: {
        authorizationDeviceTypes: [
            AuthorizationDeviceType.EXTERNAL,
            AuthorizationDeviceType.FINGERPRINT,
        ],
        deviceTypes: [
            DEVICE_ANY,
            DEVICE_GRID_CARD,
            DEVICE_NONE,
            DEVICE_PASSWORD,
            DEVICE_SOFT_TOKEN,
        ],
    },
    changeLanguage: Localization.changeLanguage,
    currentLanguage: Localization.currentLanguage,
    translate: Localization.translate,
    translateWithDefault: Localization.translateWithDefault,
    translateEnum: Localization.translateEnum,
    checkPersonId: ClarienResources.checkPersonId,
    formatPersonId: ClarienResources.formatPersonId,
    parseRouteParam: ClarienResources.parseRouteParam,
    parseServiceParam: ClarienResources.parseServiceParam,
    route: ClarienResources.route,
    searchablePersonId: ClarienResources.searchablePersonId,
    servicePersonId: ClarienResources.servicePersonId,
};

interface ClarienAppProps {
    config: IdentityConfig;
    resources: IdentityResources;
}
interface ClarienAppState {
    step: number;
    collapsed: boolean;
}

const LOGIN_STEP_FORM: number = 0;
const LOGIN_STEP_FINISHED: number = 1;

class ClarienApp extends React.Component<ClarienAppProps, ClarienAppState> {
    constructor(props: ClarienAppProps) {
        super(props);
        this.state = {
            step: LOGIN_STEP_FORM,
            collapsed: true,
        };

        ClarienResources.configure(config)
            .resources(resources);
    }

    loginStepCoordinator = (status: LoginStepStatus): LoginStepType | undefined => {
        switch (status) {
            case LoginStepStatus.SUCCESS:
                console.log('[app][loginStepCoordinator] Fin del flujo');
                this.setState({ step: LOGIN_STEP_FORM });
                return undefined;
            default:
                console.log('[app][loginStepCoordinator] Se retorna formulario login');
                this.setState({ step: LOGIN_STEP_FINISHED });
                return LoginStepUsernamePassword;
        }
    }

    handleCollapse = (collapsed: boolean) => {
        this.setState({ collapsed });
    }

    render = (): ReactNode => (
        <BrowserRouter>
            <IdentityApp config={this.props.config}
                resources={this.props.resources}
                sideMenu={SideMenu}
                navBar={<NavBar handleCollapse={this.handleCollapse} />}
                collapsed={this.state.collapsed}
                handleLoginStepFinished={this.loginStepCoordinator}>
                <Route path={USER_SEARCH_ROUTE} render={() =>
                    <UserContainer>
                        {UserNavigation}
                    </UserContainer>
                } />
                <Route path={CURUS_ON_BOARDING_ROUTE} render={() =>
                    <Switch>
                        <PermissionRoute key="onBoardingFlowInfoClarien" permissions={[ON_BOARDING_ADMIN_INSTANCE_GET_PERMISSION]} path={CURUS_ON_BOARDING_FLOW_ROUTE} exact component={OnBoardingFlowInfo} />,
                        {CurusOnBoardingNavigation}
                        <Redirect to={NOT_FOUND_ROUTE} />
                    </Switch>
                } />
                <Route path={CURUS_ON_BOARDING_PORTAL_ROUTE} render={() =>
                    <Switch>
                        <PermissionRoute key="taskFlowInfoClarien" permissions={[ON_BOARDING_ADMIN_INSTANCE_GET_PERMISSION]} path={CURUS_ON_BOARDING_PORTAL_FLOW_ROUTE} exact component={TaskFlowInfo} />,
                        {CurusOnBoardingNavigation}
                        <Redirect to={NOT_FOUND_ROUTE} />
                    </Switch>
                } />
                <Route path={MAIN_ROUTE} exact render={() =>
                    <CardsMenu menuItems={[
                        USER_NAVIGATION_ENTRY_POINT,
                        CURUS_ON_BOARDING_NAVIGATION_ENTRY_POINT,
                        CURUS_ON_BOARDING_PORTAL_NAVIGATION_ENTRY_POINT
                    ]} />
                } />
                <Redirect to={NOT_FOUND_ROUTE} />
            </IdentityApp>
        </BrowserRouter>
    )
}

ReactDOM.render(
    <ClarienApp config={config} resources={resources} />,
    document.getElementById('root')
);

require('assets/styles/santander.css');
