import { AppContextProps, withAppContext } from "@identity/context";
import React, { ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";

export interface DocumentImageProps extends RouteComponentProps, AppContextProps {
    image: string;
}
export interface DocumentImageState {
}

export class DocumentImage extends React.Component<DocumentImageProps, DocumentImageState> {

    constructor(props: DocumentImageProps) {
        super(props);
        this.state = {
        }

    } 

    render = (): ReactNode => (
        <div>
            {this.props.image === undefined && <div className="card-image">
                {this.props.resources.translate('image-downloading')}
            </div>}
            {this.props.image === null && <div className="card-image">
                {this.props.resources.translate('image-not-found')}
            </div>}
            {this.props.image && <div className="card-image">
                <img  id="docImage" src={this.props.image} />               
            </div>}
        </div>
    )
}

export default withAppContext(DocumentImage);