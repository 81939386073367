import { AppContextProps, withAppContext } from "@identity/context";
import { Match, OtpValidation, VerificationOtp } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";

export interface ValidationOtpProps extends RouteComponentProps, AppContextProps {
    validation: OtpValidation;
    tableOptions: any;
}
export interface ValidationOtpState {
    selected: OtpValidation | undefined;
}

class ValidationOtp extends React.Component<ValidationOtpProps, ValidationOtpState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: ValidationOtpProps) {
        super(props);
        this.state = {
            selected: undefined,
        };
        this.props.configureClass(this.obParser);
    }



    render = (): ReactNode => {
        const rows: JSX.Element[] = this.props.validation.matches.map((f: Match, i: number) =>
            <tr
                className={"tr-clickable"}
                key={i}>
                <td className="text-center">{this.props.resources.translateEnum(f.matchedData.toUpperCase())}</td>
                <td className="text-center">{f.dataSubmitted}</td>
                <td className="text-center">{f.dataExtracted}</td>
                <td className="text-center" >{this.obParser.formatResult(f.result)}</td>
                <td>{f.comment}</td>
            </tr>
        );
        const colClass: string = this.props.validation.dates ? 'col-md-6' : 'col-md-12';
        return (
            <div>
                <div className="row">
                    <div className={colClass}>
                        <div className={`card`}>
                            <div className="card-header">
                                <div className="card-title">
                                    <div className="title">{this.props.resources.translate('otp-authentication')}</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-custom">
                                    <tbody className="data">
                                        {this.props.validation.contact && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('contact-info')}:</th>
                                            <td colSpan={3}>{this.props.validation.contact}</td>
                                        </tr>}
                                        {this.props.validation.deliveryType && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('delivery-type')}:</th>
                                            <td colSpan={3}>{this.props.validation.deliveryType}</td>
                                        </tr>}
                                        {this.props.validation.status && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('result')}:</th>
                                            <td colSpan={3}>{this.obParser.formatResult(this.props.validation.status.result)}</td>
                                        </tr>}
                                        {this.props.validation.status.comment && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('comment')}:</th>
                                            <td colSpan={3}>{this.props.validation.status.comment}</td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {this.props.validation.dates && <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title">
                                    <div className="title">{this.props.resources.translate('validation-dates')}</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-custom">
                                    <tbody className="data">
                                        {this.props.validation.dates.created && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('date-creation')}:</th>
                                            <td colSpan={3}>{this.props.validation.dates.created.toString()}</td>
                                        </tr>}
                                        {this.props.validation.dates.expiry_timestamp && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('date-expiration')}:</th>
                                            <td colSpan={3}>{this.props.validation.dates.expiry_timestamp.toString()}</td>
                                        </tr>}
                                        {this.props.validation.dates.last_event && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('date-last-event')}:</th>
                                            <td colSpan={3}>{this.props.validation.dates.last_event.toString()}</td>
                                        </tr>}
                                        {this.props.validation.dates.completed && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('date-completed')}:</th>
                                            <td colSpan={3}>{this.props.validation.dates.completed.toString()}</td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <div className="title">{this.props.resources.translate('matchs')}</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="panel-tabs">
                                <div className="panel-body scrollable">
                                    {this.props.validation.matches &&
                                        <div className="table-responsive">
                                            <table className="table table-bordered ellipsis fixed">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center col-md-2">{this.props.resources.translate('match')}</th>
                                                        <th className="text-center col-md-2">{this.props.resources.translate('data-extracted')}</th>
                                                        <th className="text-center col-md-2">{this.props.resources.translate('data-submitted')}</th>
                                                        <th className="text-center col-md-2">{this.props.resources.translate('result')}</th>
                                                        <th className="text-center col-md-2">{this.props.resources.translate('comment')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rows}
                                                </tbody>
                                            </table>
                                        </div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withAppContext(ValidationOtp);