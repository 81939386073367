import { withAppContext } from "@identity/context";
import { AppContextProps } from "@identity/context";
import { Match } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React from "react";
import { ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";
import Popup from "reactjs-popup";

export interface DocumentMatchInfoPopUpProps extends RouteComponentProps, AppContextProps {
    open: boolean;
    documentMatch: Match;
    close: () => void;
}
export interface DocumentMatchInfoPopUpState { }

class DocumentMatchInfoPopUp extends React.Component<DocumentMatchInfoPopUpProps, DocumentMatchInfoPopUpState>{
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: DocumentMatchInfoPopUpProps) {
        super(props);
        this.props.configureClass(this.obParser);
    }

    render = (): ReactNode => (
        <Popup open={this.props.open} closeOnDocumentClick={false}>
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <div className="title">{this.props.resources.translate('document-field')}</div> <br />
                    </div>
                </div>
                <div className="card-body">
                    {this.props.documentMatch && <table className="table table-custom text-break">
                        <tbody className="data">
                            {this.props.documentMatch.matchedData && <tr>
                                <th className="col-lg-3">{this.props.resources.translate('Match')}:</th>
                                <td colSpan={3}>{this.props.resources.translateEnum(this.props.documentMatch.matchedData.toUpperCase())}</td>
                            </tr>}
                            {this.props.documentMatch.dataExtracted && <tr>
                                <th className="col-lg-3">{this.props.resources.translate('data-extracted')}:</th>
                                <td colSpan={3}>{this.props.documentMatch.dataExtracted}</td>
                            </tr>}
                            {this.props.documentMatch.dataSubmitted && <tr>
                                <th className="col-lg-3"> {this.props.resources.translate('data-submitted')}:</th>
                                <td colSpan={3}>{this.props.documentMatch.dataSubmitted}</td>
                            </tr>}
                            {this.props.documentMatch.result && <tr>
                                <th className="col-lg-3">{this.props.resources.translate('result')}:</th>
                                <td colSpan={3}>{this.obParser.formatResult(this.props.documentMatch.result)}</td>
                            </tr>}
                            {this.props.documentMatch.comment && <tr>
                                <th className="col-lg-3">{this.props.resources.translate('comment')}:</th>
                                <td colSpan={3}>{this.props.documentMatch.comment}</td>
                            </tr>}
                        </tbody>
                    </table>}
                    <div className="margin-card-top">
                        <button type="button" className="btn btn-info" onClick={this.props.close}>{this.props.resources.translate('close')}</button>
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default withAppContext(DocumentMatchInfoPopUp);