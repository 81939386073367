import React, { ReactNode } from "react";
import { OnBoardingFlowViewProps } from "@identity/views-on-boarding/flow";
import { withAppContext } from "@identity/context";
import { Notifications, OnboardingApplicationFlow, PaginatedResult, StepFlow, ValidationType } from "interfaces";
import OnBoardingServices from "services/onboarding";
import { ApiResponseError } from "@curus/api";
import { RouteNotificationParams } from "routing/param";
import OnboardingJointList from "components/on-boarding/flow/list/joints"
import OnBoardingFlowInfo from "components/on-boarding/flow/info";
import StepOtpInfo from "components/on-boarding/flow/validation/otp";
import StepIdentityInfo from "components/on-boarding/flow/validation/identity";
import StepAgreementInfo from "components/on-boarding/flow/step/agreement/info";


export interface ClarienOnBoardingJointFlowViewProps extends OnBoardingFlowViewProps { }
export interface ClarienOnBoardingJointFlowViewState {
    onboardingApplicationFlows: OnboardingApplicationFlow[] | undefined;
    notifications: Notifications[];
    tableOptions: any;
    routeNotifications: RouteNotificationParams | undefined;
    notificationType: string | undefined
    selected: OnboardingApplicationFlow;
    flow: OnboardingApplicationFlow;
    
}

export class ClarienOnBoardingJointFlowView<P extends ClarienOnBoardingJointFlowViewProps, S extends ClarienOnBoardingJointFlowViewState> extends React.Component<P, S> {
    protected obServices: OnBoardingServices = new OnBoardingServices();

    componentDidMount = (): void => {
        this.fetchJointFlows();
        this.props.showLoading(true);

    }

    constructor(props: P) {
        super(props);
        this.state = {
            onboardingApplicationFlows: undefined,
            tableOptions: {
                noDataText: this.props.resources.translate('table-empty'),
            },
            routeNotifications: undefined,
            notificationType: undefined

        } as S;
        this.props.configureClass(this.obServices);
    }

    fetchJointFlows = (): void => {
        this.obServices
            .jointFlows({
                filter: {
                    principalId: this.props.match.params.instanceFlowId
                }
            })
            .then((paginatedFlow: PaginatedResult<OnboardingApplicationFlow>) => {
                this.setState({ onboardingApplicationFlows: paginatedFlow.content });
                console.log('con parser: ');
                console.log(this.state.onboardingApplicationFlows);
                
                this.props.showLoading(false);

            })
            .catch((response: ApiResponseError) => {
                this.props.showAlert(response.message);
                this.props.showLoading(false);
            });
    }

    fetchFlow = (instanceFlowId:string): void => {
        this.props.showLoading(true);
        this.obServices
            .flow({instanceFlowId})
            .then((flow: OnboardingApplicationFlow) => {
                this.setState({ flow });
                console.log('con parser: ');
                console.log(flow);
                this.fetchIDVNotifications();
                this.props.showLoading(false);

            })
            .catch((response: ApiResponseError) => {
                this.props.showAlert(response.message);
                this.props.showLoading(false);
            });
    }

    fetchIDVNotifications = (): void => {
        this.props.showLoading(true);
        this.obServices
            .notifications({onboardingId:this.state.flow?.id,notificationType: "IDV"})
             .then((notifications: Notifications[])=>{
                 this.props.showLoading(false);
                 this.setState({notifications});
             })
             .catch(()=> {
                 this.props.showLoading(false);
             })
    }

    
    fetchNotifications = (notificationType: string): Promise<Notifications[]> => {
        this.props.showLoading(true);
        console.log(this.state.notificationType);
        return this.obServices
            .notifications({onboardingId:this.state.flow?.id,notificationType: notificationType})
             .then((notifications: Notifications[])=>{
                 this.props.showLoading(false);
                 return notifications;
             })
             .catch(()=> {
                 this.props.showLoading(false);
                 return [];
             })
        }   

    handleSelected? = (selected: OnboardingApplicationFlow): void => {
        console.log(selected)
        this.fetchFlow(selected.id);
        this.setState({ selected });
    }

    handleShowExtraInfo = async (type: ValidationType, detail: any): Promise<JSX.Element | undefined> => {
        const step: StepFlow = this.state.flow!.steps.find((s: StepFlow) => s.type === type)!;
        this.fetchJointFlows();
        switch (type) {
            case ValidationType.OTP: {
                return <div>
                    {this.obServices.obParser.formatStepStatusAsAlert(step.status)}
                    <div className="card">
                        <div className="card-body"><StepOtpInfo step={step}
                            tableOptions={this.state.tableOptions} /></div>
                    </div>
                </div>
            }
            case ValidationType.IDENTITY: {
                
                return <div>
                    {this.obServices.obParser.formatStepStatusAsAlert(step.status)}
                    <div className="card">
                    <div className="card-body"><StepIdentityInfo step={step}
   
                            tableOptions={this.state.tableOptions} /></div>
                    </div>
                </div>
            }
            case ValidationType.AGREEMENT: {
                let notificationType= "OSS";
                this.setState({notificationType});
                let notifications: Notifications[] = await this.fetchNotifications(notificationType);

                return <div>
                    {this.obServices.obParser.formatStepStatusAsAlert(step.status)}
                    <div className="card">
                    <div className="card-body"><StepAgreementInfo step={step}
                            notifications={notifications}
                            tableOptions={this.state.tableOptions} /></div>
                    </div>
                </div>
            }
        }
    }



    render = (): ReactNode => {
        return (
            <div className="card-body">
                        <div className="panel fresh-color panel-info">
                            <div className="panel-heading">
                                <div className="title">{this.props.resources.translate('select-joint-account')}</div>
                            </div>
                            <OnboardingJointList onboardingApplicationFlows={this.state.onboardingApplicationFlows}
                                handleSelected={this.handleSelected} />
                        </div>
                        
                            
                        {this.state.flow && <OnBoardingFlowInfo flow={this.state.flow}
                        handleShowExtraInfo={this.handleShowExtraInfo} notifications={this.state.notifications}
                        tableOptions={this.state.tableOptions}/>}
                        
                    </div>
        )
        
    }
}

export default withAppContext(ClarienOnBoardingJointFlowView);
