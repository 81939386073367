import { IdentityNetworkClass } from "@identity/classes";
import { ON_BOARDING_INSTANCES_API_ROUTE, ON_BOARDING_INSTANCE_API_ROUTE } from "@identity/routing-on-boarding";
import { RouteInstanceFlowParams } from "@identity/routing-on-boarding/param";
import { AgreementAuditEvent, AgrementPdf, AttachmentBase64, Notifications, OnboardingAgreementPackage, OnboardingApplicationFlow, OnboardingApplicationFlowAPI, PaginatedResult, SearchFilterAPI, TaskAgreementPackage, TaskApplicationFlow, TaskApplicationFlowAPI } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import { ON_BOARDING_AGREEMENT_PACKAGE_API_ROUTE, ON_BOARDING_AGREEMENT_DOCUMENT_API_ROUTE, ON_BOARDING_AGREEMENT_ATTACHMENT_API_ROUTE, ON_BOARDING_AGREEMENT_AUDIT_API_ROUTE, ON_BOARDING_NOTIFICATION_API_ROUTE, ON_BOARDING_INSTANCES_JOINT_API_ROUTE, ON_BOARDING_PORTAL_INSTANCES_API_ROUTE, ON_BOARDING_PORTAL_INSTANCE_API_ROUTE, ON_BOARDING_PORTAL_JOINT_API_ROUTE, ON_BOARDING_PORTAL_NOTIFICATION_API_ROUTE, ON_BOARDING_PORTAL_AGREEMENT_PACKAGE_API_ROUTE, ON_BOARDING_PORTAL_AGREEMENT_DOCUMENT_API_ROUTE, ON_BOARDING_PORTAL_AGREEMENT_ATTACHMENT_API_ROUTE, ON_BOARDING_PORTAL_AGREEMENT_AUDIT_API_ROUTE } from "routing";
import { RouteAgreementPackageParams, RouteAgreementDocumentParams, RouteAgreementAttachmentParams, RouteNotificationParams, RouteTaskNotificationParams } from "routing/param";

export default class OnBoardingServices extends IdentityNetworkClass {
    public obParser: OnBoardingParser = new OnBoardingParser();


    flows = (filter: SearchFilterAPI): Promise<PaginatedResult<OnboardingApplicationFlow>> => {
        return this.api.call(ON_BOARDING_INSTANCES_API_ROUTE, { body: filter })
            .then((response: any) => {
                console.log('[flows] OK');
                return this.obParser.parsePaginatedResult<OnboardingApplicationFlowAPI, OnboardingApplicationFlow>(response.data, this.obParser.parseOnboardingApplicationFlow);
            })
            .catch(this.handleServiceError);
    }

    flow = (pathParams: RouteInstanceFlowParams): Promise<OnboardingApplicationFlow> => {
        return this.api.call(ON_BOARDING_INSTANCE_API_ROUTE, { pathParams })
            .then((response: any) => {
                console.log('[flow] OK');
                console.log(response.data);
                return this.obParser.parseOnboardingApplicationFlow(response.data);
            })
            .catch(this.handleServiceError);
    }

    taskFlows = (filter: SearchFilterAPI): Promise<PaginatedResult<TaskApplicationFlow>> => {
        return this.api.call(ON_BOARDING_PORTAL_INSTANCES_API_ROUTE, { body: filter })
            .then((response: any) => {
                console.log('[task flows] OK');
                return this.obParser.parsePaginatedResult<TaskApplicationFlowAPI, TaskApplicationFlow>(response.data, this.obParser.parseTaskApplicationFlow);
            })
            .catch(this.handleServiceError);
    }

    taskFlow = (pathParams: RouteInstanceFlowParams): Promise<TaskApplicationFlow> => {
        return this.api.call(ON_BOARDING_PORTAL_INSTANCE_API_ROUTE, { pathParams })
            .then((response: any) => {
                console.log('[task flow] OK');
                console.log(response.data);
                return this.obParser.parseTaskApplicationFlow(response.data);
            })
            .catch(this.handleServiceError);
    }

    jointFlows = (filter: SearchFilterAPI): Promise<PaginatedResult<OnboardingApplicationFlow>> => {        
        console.log(filter);
        return this.api.call(ON_BOARDING_INSTANCES_JOINT_API_ROUTE, { body: filter })
            .then((response: any) => {
                console.log('[joint flows] OK');
                return this.obParser.parsePaginatedResult<OnboardingApplicationFlowAPI, OnboardingApplicationFlow>(response.data, this.obParser.parseOnboardingApplicationFlow);
            })
            .catch(this.handleServiceError);
    }

    jointTaskFlows = (filter: SearchFilterAPI): Promise<PaginatedResult<TaskApplicationFlow>> => {        
        console.log(filter);
        return this.api.call(ON_BOARDING_PORTAL_JOINT_API_ROUTE, { body: filter })
            .then((response: any) => {
                console.log('[joint task flows] OK');
                return this.obParser.parsePaginatedResult<TaskApplicationFlowAPI, TaskApplicationFlow>(response.data, this.obParser.parseTaskApplicationFlow);
            })
            .catch(this.handleServiceError);
    }

    agreement = (pathParams: RouteAgreementPackageParams): Promise<OnboardingAgreementPackage> => {
        return this.api.call(ON_BOARDING_AGREEMENT_PACKAGE_API_ROUTE, { pathParams })
            .then((response: any) => {
                console.log(response.data);
                return this.obParser.parseAgreementPackage(response.data);
            }).catch(this.handleServiceError);
    }

    taskAgreement = (pathParams: RouteAgreementPackageParams): Promise<TaskAgreementPackage> => {
        return this.api.call(ON_BOARDING_PORTAL_AGREEMENT_PACKAGE_API_ROUTE, { pathParams })
            .then((response: any) => {
                console.log('[task agreement] OK')
                console.log(response.data);
                return this.obParser.parseTaskAgreementPackage(response.data);
            }).catch(this.handleServiceError);
    }

    document = (pathParams: RouteAgreementDocumentParams): Promise<any> => {
        return this.api.call(ON_BOARDING_AGREEMENT_DOCUMENT_API_ROUTE, { pathParams })
            .then((response: any) => {                
                console.log('[document] OK');
                return response.data;
            }).catch(this.handleServiceError);
    }

    taskDocument = (pathParams: RouteAgreementDocumentParams): Promise<any> => {
        return this.api.call(ON_BOARDING_PORTAL_AGREEMENT_DOCUMENT_API_ROUTE, { pathParams })
            .then((response: any) => {                
                console.log('[task document] OK');
                return response.data;
            }).catch(this.handleServiceError);
    }   

    attachment = (pathParams: RouteAgreementAttachmentParams): Promise<AttachmentBase64> => {
        return this.api.call(ON_BOARDING_AGREEMENT_ATTACHMENT_API_ROUTE, { pathParams })
            .then((response: any) => {                
                console.log('[attachment] OK');                     
                console.log(response);
                return this.obParser.parseFileBase64(response.data);
            }).catch(this.handleServiceError);
    }

    taskAttachment = (pathParams: RouteAgreementAttachmentParams): Promise<AttachmentBase64> => {
        return this.api.call(ON_BOARDING_PORTAL_AGREEMENT_ATTACHMENT_API_ROUTE, { pathParams })
            .then((response: any) => {                
                console.log('[task attachment] OK');                     
                console.log(response);
                return this.obParser.parseFileBase64(response.data);
            }).catch(this.handleServiceError);
    }  
    
    agreementAudit = (pathParams: RouteAgreementPackageParams): Promise<AgreementAuditEvent[]> => {
        return this.api.call(ON_BOARDING_AGREEMENT_AUDIT_API_ROUTE, { pathParams })
            .then((response: any) => {
                console.log('[agreementAudit] OK');   
                console.log(response.data);
                return response.data;
            }).catch(this.handleServiceError);
    }

    taskAgreementAudit = (pathParams: RouteAgreementPackageParams): Promise<AgreementAuditEvent[]> => {
        return this.api.call(ON_BOARDING_PORTAL_AGREEMENT_AUDIT_API_ROUTE, { pathParams })
            .then((response: any) => {
                console.log('[task agreementAudit] OK');   
                console.log(response.data);
                return response.data;
            }).catch(this.handleServiceError);
    }

    notifications = (pathParams: RouteNotificationParams): Promise<Notifications[]> => {
        return this.api.call(ON_BOARDING_NOTIFICATION_API_ROUTE, { pathParams })
            .then((response: any) => {
                console.log('[notifications sin parser] OK');
                console.log(response.data);
                console.log(typeof(response.data));
                console.log(this.obParser.parseNotificationsList(response.data));
                return this.obParser.parseNotificationsList(response.data);
            })
            .catch(this.handleServiceError);
    }

    taskNotifications = (pathParams: RouteTaskNotificationParams): Promise<Notifications[]> => {
        return this.api.call(ON_BOARDING_PORTAL_NOTIFICATION_API_ROUTE, { pathParams })
            .then((response: any) => {
                console.log('[tasks notifications sin parser] OK');
                console.log(response.data);
                console.log(typeof(response.data));
                console.log(this.obParser.parseNotificationsList(response.data));
                return this.obParser.parseNotificationsList(response.data);
            })
            .catch(this.handleServiceError);
    }

}