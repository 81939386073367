import { AppContextProps, withAppContext } from "@identity/context";
import { AgreementAuditEvent } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router-dom";

export interface AgreementEventListProps extends RouteComponentProps, AppContextProps {
    events: AgreementAuditEvent[];
    tableOptions: any;
}
export interface AgreementEventListState {
    selected: AgreementAuditEvent |undefined;
}

class AgreementEventList extends React.Component<AgreementEventListProps, AgreementEventListState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: AgreementEventListProps) {
        super(props);
        this.state = {
            selected: undefined,
        };
        this.props.configureClass(this.obParser);
    }

   

    render = (): ReactNode => (
        <BootstrapTable
            data={this.props.events}
            options={this.props.tableOptions}
            bordered pagination>
            <TableHeaderColumn dataField='id' isKey hidden></TableHeaderColumn>
            <TableHeaderColumn dataField='targetType' width="160" dataAlign="center" dataSort>{this.props.resources.translate('target-type')}</TableHeaderColumn>
            <TableHeaderColumn dataField='dateTime' width="210" dataAlign="center" dataSort>{this.props.resources.translate('date')}</TableHeaderColumn>
            <TableHeaderColumn dataField='type' width="250" dataAlign="center" dataSort >{this.props.resources.translate('type')}</TableHeaderColumn>
            <TableHeaderColumn dataField='target' width="200" dataAlign="center" dataSort>{this.props.resources.translate('target')}</TableHeaderColumn>
            <TableHeaderColumn dataField='user' width="160" dataAlign="center" dataSort>{this.props.resources.translate('user')}</TableHeaderColumn>
            <TableHeaderColumn dataField='userEmail' width="180" dataAlign="center" dataSort >{this.props.resources.translate('email')}</TableHeaderColumn>
            <TableHeaderColumn dataField='userIp' width="200" dataAlign="center" dataSort>{this.props.resources.translate('IP')}</TableHeaderColumn>
            <TableHeaderColumn dataField='data' width="300" dataAlign="center" dataSort>{this.props.resources.translate('data')}</TableHeaderColumn>
        </BootstrapTable>
    )
}

export default withAppContext(AgreementEventList);