import React, { ReactNode } from "react";
import { OnBoardingFlowViewProps } from "@identity/views-on-boarding/flow";
import { withAppContext } from "@identity/context";
import OnBoardingFlowInfo from "components/on-boarding/flow/info";
import StepOtpInfo from "components/on-boarding/flow/validation/otp";
import StepIdentityInfo from "components/on-boarding/flow/validation/identity";
import StepAgreementInfo from "components/on-boarding/flow/step/agreement/info";
import { Notifications, OnboardingApplicationFlow, StepFlow, ValidationType } from "interfaces";
import OnBoardingServices from "services/onboarding";
import { ApiResponseError } from "@curus/api";
import { RouteNotificationParams } from "routing/param";

export interface ClarienOnBoardingFlowViewProps extends OnBoardingFlowViewProps { }
export interface ClarienOnBoardingFlowViewState {
    flow: OnboardingApplicationFlow | undefined;
    tableOptions: any;
    routeNotifications: RouteNotificationParams | undefined;
    notificationType: string | undefined;
    notifications: Notifications[];
}

export class ClarienOnBoardingFlowView<P extends ClarienOnBoardingFlowViewProps, S extends ClarienOnBoardingFlowViewState> extends React.Component<P, S> {
    protected obServices: OnBoardingServices = new OnBoardingServices();

    componentDidMount = (): void => {
        this.fetchFlow();
        this.props.showLoading(true);
      
    }

    constructor(props: P) {
        super(props);
        this.state = {
            flow: undefined,
            tableOptions: {
                noDataText: this.props.resources.translate('table-empty'),
            },
            routeNotifications: undefined,
            notificationType: undefined

        } as S;
        this.props.configureClass(this.obServices);
    }

    fetchFlow = (): void => {
        this.props.showLoading(true);
        this.obServices
            .flow(this.props.match.params)
            .then((flow: OnboardingApplicationFlow) => {
                this.setState({ flow });
                console.log('con parser: ');
                console.log(flow);
                this.fetchIDVNotifications();
                this.props.showLoading(false);

            })
            .catch((response: ApiResponseError) => {
                this.props.showAlert(response.message);
                this.props.showLoading(false);
            });
    }

    fetchIDVNotifications = (): void => {
        this.obServices
            .notifications({onboardingId:this.state.flow?.id,notificationType: "IDV"})
             .then((notifications: Notifications[])=>{
                 this.props.showLoading(false);
                 this.setState({notifications});
             })
             .catch(()=> {
                 this.props.showLoading(false);
             })
    }

    fetchNotifications = (notificationType: string): Promise<Notifications[]> => {
        this.props.showLoading(true);
        console.log(this.state.notificationType);
        return this.obServices
            .notifications({onboardingId:this.state.flow?.id,notificationType: notificationType})
             .then((notifications: Notifications[])=>{
                 this.props.showLoading(false);
                 return notifications;
             })
             .catch(()=> {
                 this.props.showLoading(false);
                 return [];
             })
            
    }

    handleShowExtraInfo = async (type: ValidationType, detail: any): Promise<JSX.Element | undefined> => {
        const step: StepFlow = this.state.flow!.steps.find((s: StepFlow) => s.type === type)!;

        switch (type) {
            case ValidationType.OTP: {
                return <div>
                    {this.obServices.obParser.formatStepStatusAsAlert(step.status)}
                    <div className="card">
                        <div className="card-body"><StepOtpInfo step={step}
                            tableOptions={this.state.tableOptions} /></div>
                    </div>
                </div>
            }
            case ValidationType.IDENTITY: {

                return <div>
                    {this.obServices.obParser.formatStepStatusAsAlert(step.status)}
                    <div className="card">
                    <div className="card-body"><StepIdentityInfo step={step}
                            
                            tableOptions={this.state.tableOptions} /></div>
                    </div>
                </div>
            }
            case ValidationType.AGREEMENT: {
                let notificationType= "OSS";
                this.setState({notificationType});
                let notifications: Notifications[] = await this.fetchNotifications(notificationType);
              

                return <div>
                    {this.obServices.obParser.formatStepStatusAsAlert(step.status)}
                    <div className="card">
                    <div className="card-body"><StepAgreementInfo step={step}
                            notifications={notifications}
                            tableOptions={this.state.tableOptions} /></div>
                    </div>
                </div>
            }
        }
    }

    render = (): ReactNode => (
        <div>
            {this.state.flow && <OnBoardingFlowInfo flow={this.state.flow}
                handleShowExtraInfo={this.handleShowExtraInfo} notifications={this.state.notifications}
                tableOptions={this.state.tableOptions}/>}
        </div>
    )
}

export default withAppContext(ClarienOnBoardingFlowView);
