import { AppContextProps, withAppContext } from "@identity/context";
import { OtpValidation } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React, { ReactNode } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RouteComponentProps } from "react-router-dom";

export interface ValidationOtpListProps extends RouteComponentProps, AppContextProps {
    validations: OtpValidation[];
    tableOptions: any;
    handleSelected?: (selected: OtpValidation) => void;
}
export interface ValidationOtpListState {
    selected: OtpValidation | undefined;
}

class ValidationOtpList extends React.Component<ValidationOtpListProps, ValidationOtpListState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: ValidationOtpListProps) {
        super(props);
        this.state = {
            selected: undefined,
        };
        this.props.configureClass(this.obParser);
    }

    handleSelected = (selected: OtpValidation): void => {
        this.setState({ selected });
        if(this.props.handleSelected)
            this.props.handleSelected(selected);
    }

    render = (): ReactNode => (
        <BootstrapTable
            data={this.obParser.parseOtpValidationList(this.props.validations)}
            options={this.props.tableOptions} 
            selectRow={this.props.handleSelected ? { mode: 'radio', onSelect: this.handleSelected } : { mode: 'none' }}>  
            <TableHeaderColumn isKey={true} width="50" dataAlign="center" dataField='index' dataSort>#</TableHeaderColumn>    
            <TableHeaderColumn dataField='contact' dataAlign="center" dataSort>{this.props.resources.translate('contact-info')} </TableHeaderColumn>      
            <TableHeaderColumn dataField='result' dataAlign="center" width="160" dataSort dataFormat={this.obParser.formatResult}>{this.props.resources.translate('result')}</TableHeaderColumn>
            <TableHeaderColumn tdStyle={ { whiteSpace: 'normal' } } dataField='comment' dataAlign="center" dataSort>{this.props.resources.translate('comment')} </TableHeaderColumn>
            <TableHeaderColumn dataField='creationDate' dataAlign="center" dataSort>{this.props.resources.translate('date-creation')}</TableHeaderColumn>  
        </BootstrapTable>
    )
}

export default withAppContext(ValidationOtpList);


