import DateFnsUtils from "@date-io/date-fns";
import { AppContextProps, withAppContext } from "@identity/context";
import { createMuiTheme, Grid, ThemeProvider } from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";

const materialTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#013660'
        },
        secondary: {
            main: '#f78832'
        }

    },
});

export interface OnBoardingFlowFilterProps extends RouteComponentProps, AppContextProps {
    selectedSearch: Boolean;
    handleSearchSelected: () => void;
    selectedInitDate: Date;
    selectedEndDate: Date;
    cellphoneFilter: string;
    handleDateInitChange: (selectedDate: any) => void;
    handleDateEndChange: (selectedDate: any) => void;
    handleCellphoneFilter: (cellphone: any) => void;
    handleApplySelected: () => void;
    handleCancel: () => void;
}
export interface OnBoardingFlowFilterState {


}

class OnBoardingFlowFilter extends React.Component<OnBoardingFlowFilterProps, OnBoardingFlowFilterState> {

    constructor(props: OnBoardingFlowFilterProps) {
        super(props);

    }




    render = (): ReactNode => {
        return (
            <div>
                <Grid container alignItems="flex-start" justify="flex-end" direction="row">

                    {!this.props.selectedSearch && <button type="button" onClick={this.props.handleSearchSelected} className="btn"><i className="fa fa-search"></i></button>}
                    {this.props.selectedSearch &&

                        <div className='col-md-6'>
                            <div className='card'>
                                <div className="card-header">
                                    <div className="card-title">
                                        <div className="title">{this.props.resources.translate('select-dates')}</div>

                                    </div>
                                </div>
                                <div className="card-body">
                                    <div >
                                        <ThemeProvider theme={materialTheme}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <div className='col-lg-3'>

                                                    <DateTimePicker
                                                        value={this.props.selectedInitDate}
                                                        disableFuture
                                                        onChange={this.props.handleDateInitChange}
                                                        label="Start Date *"
                                                        showTodayButton
                                                    />
                                                </div>
                                                <div className='col-lg-3'>
                                                    <DateTimePicker
                                                        value={this.props.selectedEndDate}
                                                        disableFuture
                                                        onChange={this.props.handleDateEndChange}
                                                        label="End Date *"
                                                        showTodayButton
                                                    />
                                                </div>
                                            </MuiPickersUtilsProvider>
                                        </ThemeProvider>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="inputCellphoneFilter" className="col-sm-2 control-label">{this.props.resources.translate('phone-mobile')}:</label>
                                        <div className="col-sm-4">
                                            <input type="text" onChange={this.props.handleCellphoneFilter} value={this.props.cellphoneFilter} name="cellphoneFilter" className="form-control" id="cellphoneFilter" placeholder={this.props.resources.translate("Enter cell phone number to search")} />
                                        </div>
                                    </div>

                                    <div style={{ textAlign: 'right' }}>
                                        <button className="btn btn-info" onClick={this.props.handleApplySelected}>{this.props.resources.translate('apply')}</button>
                                        <button className="btn btn-cancel" onClick={this.props.handleCancel}>{this.props.resources.translate('cancel')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    }
                </Grid>

            </div>
        )
    }
}

export default withAppContext(OnBoardingFlowFilter);