import { AppContextProps, withAppContext } from "@identity/context";
import { Challengue, Signer } from "interfaces";
import OnBoardingParser from "parser/onboarding";
import React from "react";
import { ReactNode } from "react";
import { RouteComponentProps } from "react-router-dom";


export interface SignerInfoProps extends RouteComponentProps, AppContextProps {
    signer: Signer;
    tableOptions: any;
}
export interface SignerInfoState {
    selected: Signer | undefined;
}

class SignerInfo extends React.Component<SignerInfoProps, SignerInfoState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: SignerInfoProps) {
        super(props);
        this.state = {
            selected: undefined,
        };
        this.props.configureClass(this.obParser);
    }



    render = (): ReactNode => {
        const colClass: string = 'col-md-6';
        const rows: JSX.Element[] = this.props.signer.auth.challenges.map((c: Challengue, i: number) =>
            <tr
                className={"tr-clickable"}
                key={i}>
                <td className="text-center">{this.props.signer.auth.scheme}</td>
                <td className="text-center">{c.question}</td>
            </tr>
        );

        return (
            <div>
                <div className="row">
                    <div className={colClass}>
                        <div className={`card`}>
                            <div className="card-header">
                                <div className="card-title">
                                    <div className="title">{this.props.resources.translate('signer-information')}</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table table-custom">
                                    <tbody className="data">
                                        {this.props.signer.id && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('id')}:</th>
                                            <td colSpan={3}>{this.props.signer.id}</td>
                                        </tr>}
                                        {this.props.signer.signerType && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('signer-type')}:</th>
                                            <td colSpan={3}>{this.props.resources.translateEnum(this.props.signer.signerType)}</td>
                                        </tr>}
                                        {this.props.signer.email && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('email')}:</th>
                                            <td colSpan={3}>{this.props.signer.email}</td>
                                        </tr>}
                                        {this.props.signer.firstName && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('name')}:</th>
                                            <td colSpan={3}>{(this.props.signer.firstName)} {(this.props.signer.lastName)}</td>
                                        </tr>}
                                        {this.props.signer.company && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('company')}:</th>
                                            <td colSpan={3}>{(this.props.signer.company)}</td>
                                        </tr>}
                                        {this.props.signer.address && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('address')}:</th>
                                            <td colSpan={3}>{(this.props.signer.address)}</td>
                                        </tr>}
                                        {this.props.signer.language && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('language')}:</th>
                                            <td colSpan={3}>{(this.props.signer.language)}</td>
                                        </tr>}
                                        {this.props.signer.created && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('date-creation')}:</th>
                                            <td colSpan={3}>{(this.props.signer.created)}</td>
                                        </tr>}
                                        {this.props.signer.updated && <tr>
                                            <th className="col-lg-3">{this.props.resources.translate('date-last-event')}:</th>
                                            <td colSpan={3}>{(this.props.signer.updated)}</td>
                                        </tr>}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {this.props.signer.auth.scheme !== "NONE" &&
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">
                                        <div className="title">{this.props.resources.translate('auth')}</div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="panel-tabs">
                                        <div className="panel-body scrollable">
                                            {this.props.signer.auth &&
                                                <div className="table-responsive">
                                                    <table className="table table-bordered ellipsis fixed">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center col-md-2">{this.props.resources.translate('scheme')}</th>
                                                                <th className="text-center col-md-2">{this.props.resources.translate('phone-mobile')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {rows}
                                                        </tbody>
                                                    </table>
                                                </div>}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>}


                </div>

            </div>
        )
    }
}

export default withAppContext(SignerInfo);