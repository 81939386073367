import React, { ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import { VALIDATION_TYPE_ICONS } from "@identity/constants-on-boarding";
import { AppContextProps, withAppContext } from "@identity/context";
import { StepFlow, ValidationType } from "interfaces";
import OnBoardingParser from "parser/onboarding";

export interface OnBoardingFlowStepProps extends RouteComponentProps, AppContextProps {
    number: number;
    step: StepFlow;
    colSize?: number;    
    activeStep?: ValidationType;
    handleShowExtraInfo?: (type: ValidationType, detail: any) => void;
}
export interface OnBoardingFlowStepState { }

class OnBoardingFlowStep extends React.Component<OnBoardingFlowStepProps, OnBoardingFlowStepState> {
    protected obParser: OnBoardingParser = new OnBoardingParser();

    constructor(props: OnBoardingFlowStepProps) {
        super(props);
        this.props.configureClass(this.obParser);
    }
    handleShowExtraInfo = (): void => {
        if (this.props.handleShowExtraInfo === undefined) return;
        this.props.handleShowExtraInfo(this.props.step.type, this.props.step.details);
    }


    render = (): ReactNode => {
        const icon: string | undefined = VALIDATION_TYPE_ICONS[this.props.step.type];
        
        const fullOpacity: boolean = this.props.activeStep === undefined || this.props.activeStep === this.props.step.type;
        return (
            <div className={`col-md-${this.props.colSize || 12} instance-flow-step`}
                style={{ opacity: fullOpacity ? '100%' : '50%' }}
                onClick={this.handleShowExtraInfo}>
                {this.obParser.formatStepStatusAsTitle(this.props.step.status, this.props.number)}
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            {icon !== undefined && <i className={`fas fa-${icon}`}></i>}
                            <div className="card-title-flex title">
                                {this.props.resources.translateEnum(`VALIDATION_TYPE_${this.props.step.type}`)}
                            </div>
                            {this.obParser.formatStepStatus(this.props.step.status)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withAppContext(OnBoardingFlowStep); 